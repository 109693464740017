import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import { Provider } from "react-redux";

//Store
import store from "./Redux/store";

//
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// rrd
import { BrowserRouter } from "react-router-dom";
// import Intro from './pages/Intro/Intro'
import Home from'./pages/Home/Home'
const App = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <Suspense fallback={<Home />} >
      <App />
    </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
