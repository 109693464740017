import {
  FETCH_WISHLIST,
  ADD_PRODUCT_TO_WISHLIST_FALIUR,
  ADD_PRODUCT_TO_WISHLIST_SUCCESS,
  ADD_PRODUCT_TO_WISHLIST,
  REMOVE_PRODUCT_FROM_WISHLIST,
  REMOVE_PRODUCT_FROM_WISHLIST_FALIUR,
  REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
} from "../../constants/WishList.type";

const initialState = {
  wishList: null,
  loading: false,
};

const wishListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WISHLIST:
      return {
        ...state,
        wishList: action.payload,
      };
    case ADD_PRODUCT_TO_WISHLIST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRODUCT_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PRODUCT_TO_WISHLIST_FALIUR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case REMOVE_PRODUCT_FROM_WISHLIST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PRODUCT_FROM_WISHLIST_FALIUR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default wishListReducer;
