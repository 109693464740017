import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, lazy, Suspense } from "react";

import { useSelector, useDispatch } from "react-redux";



import { DesignCarosuel } from "../../components/DesignCarosuel/DesignCarosuel";

import { DesignListView } from "../../components/DesignListView/DesignListView";

import { DesignTextView } from "../../components/DesignTextView/DesignTextView";
import { DesignSingleLineGridView } from "../../components/DesignSingleLineGridView/DesignSingleLineGridView";
import { DesignGridView } from "../../components/DesignGridView/DesignGridView";
import { DesignHorizontalListProductView } from "../../components/DesignHorizontalListProductView/DesignHorizontalListProductView";
import { DesignProductGridView } from "../../components/DesignProductGridView/DesignProductGridView";
import { DesignProductListView } from "../../components/DesignProductListView/DesignProductListView";

import { DesignGridView3Item } from "../../components/DesignGridView3Item/DesignGridView3Item";

import AllProductListBackHeaderBar from "../../components/Header/AllProductListBackHedareBar.component";

//action
import { getCategoryWiseProduct } from "../../Redux/actions/Product/Product.action";
// import { featuredProductByCat, getSingleCategory, getFeaturedProduct } from "../../Redux/actions/Category/Category.action";
import { homePageDesign } from "../../Redux/actions/Home/Home.action";
const AllProductCardList = lazy(() => import('../../components/HomeComponents/AllProductsCardList'))


export const DesignInnerPage = ({ match, pageId, location }) => {
  const dispatch = useDispatch();
  const design = useSelector((state) => state?.home);
  const level1 = useSelector((state) => state?.home?.category1);
  const level2 = useSelector((state) => state?.home?.category2);
  const sublevel = useSelector((state) => state?.home?.category);
  const designs = design?.homePageDesign?.design;
  // const totalPage = design?.homePageDesign?.totalPages
  // const currentPage = design?.homePageDesign?.currentPage
  const pageDesign = useSelector((state) => state?.home?.homePageDesign);

  const [limit, setlimit] = React.useState(80);
  const [allDesign, setallDesign] = React.useState()
  const [productPage, setproductPage] = React.useState(2)

console.log(productPage)

  React.useEffect(() => {
    if(design && designs?.length !== 0){
      setallDesign(designs)
    }else{
      setallDesign([])
    }
  },[design, designs])

  console.log(`PAGE_DESIGN: `);
  console.log(designs);
  console.log(design);

  const id = pageId === undefined ? match?.params?.id : pageId;
  const level = pageId === undefined ? match?.params?.level : pageId

  const getDesign = (design) => {
    const designType = design?.["design_type"]?.toLowerCase();

    if (!design?.["status"]) {
      return <Box />;
    }

    switch (designType) {
      case "carosuel":
        return <DesignCarosuel {...{ design }} id={id} />;
      case "listview":
        return <DesignListView {...{ design }} id={id} />;
      case "gridview":
        return <DesignGridView {...{ design }} id={id} />;
      case "grid_view":
        return <DesignSingleLineGridView {...{ design }} id={id} />;
      case "textview":
        return <DesignTextView {...{ design }} id={id} />;
      case "productlistview":
        return <DesignProductListView {...{ design }} id={id} />;
      case "productgridview":
        return <DesignProductGridView {...{ design }} id={id} />;
      case "horizontal_list_product_view":
        return <DesignHorizontalListProductView {...{ design }} id={id} />;
      case "DesignGridView3Item".toLowerCase():
        return <DesignGridView3Item {...{ design }} id={id} />;
      default:
        return <Box>Default</Box>;
    }
  };

  const title = location && location.state;


  useEffect(() => {
    dispatch(homePageDesign(id, limit, 1, level));
    if(localStorage.getItem('pageId') !== id){
      dispatch(getCategoryWiseProduct(id, 1, 10, level));
    }else {
      setproductPage(parseInt(localStorage.getItem('page')))
      localStorage.removeItem('pageId')
      localStorage.removeItem('page')
    }
    // dispatch(featuredProductByCat(id));
    // dispatch(getSingleCategory(id));
    // dispatch(getFeaturedProduct(id))
  }, [id]);
  
// const fetchMoreData = () => {
  //     setpage(page + 1)
  //     getHomePagedesign(id, limit, page).then(res => {
  //       if(res.data?.design?.length !== 0){
  //         setallDesign(allDesign.concat(res.data?.design))
  //       }else{
  //         sethasmorDesign(false)
  //       }
  //       settotalPage(res.data?.totalPages)
  //       setcurrentPage(res.data?.currentPage)
  //       console.log(res.data)
  //     }).catch(error => {
  //       console.log(error)
  //     }) 
//   }
// }

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      {pageId !== undefined ? (
        <Box />
      ) : (
        <AllProductListBackHeaderBar
          headerTitle={title ? title : pageDesign?.["pagename"]}
        />
      )}

      {/* {pageId !== undefined ? <Box /> : <DesignSubCategoryList id={id} />} */}
      {/* <InfiniteScroll
          dataLength={allDesign?.length ? allDesign?.length : 10}
          next={fetchMoreData}
          hasMore={hasmorDesign}
          loader={
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '1rem 0' }} >
            
          </div>
        }
       
        >
        </InfiniteScroll> */}
      {level === '12345' ? 
      !design?.loading ? (
        allDesign?.map((e, index) => {
          return getDesign(allDesign[index]);
        })
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <Skeleton variant="rect" animation="wave" width="100%" height={200} />
        </div>
      ) : level === 'category2' ?
      !level1?.loading ? level1?.banner?.design?.map((e, index) => {
        return getDesign(level1?.banner?.design[index]);
      }) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <Skeleton variant="rect" animation="wave" width="100%" height={200} />
        </div>
      ) : level === 'category3' ? 
      !level2?.loading ? level2?.banner?.design?.map((e, index) => {
        return getDesign(level2?.banner?.design[index]);
      }) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <Skeleton variant="rect" animation="wave" width="100%" height={200} />
        </div>
      ) : level === 'category1' && 
      !sublevel?.loading ? sublevel?.banner?.design?.map((e, index) => {
        return getDesign(sublevel?.banner?.design[index]);
      }) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          <Skeleton variant="rect" animation="wave" width="100%" height={200} />
        </div>
      )}
      <Suspense fallback='Loading' >
        <AllProductCardList id={id} page={productPage} setpage={setproductPage} level={level} />
      </Suspense>
    </div>
  );
};
