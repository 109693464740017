import {
  Button,
  Divider,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Modal,
  useTheme,
  useScrollTrigger,
  Slide,
  AppBar,
  Toolbar,
  Badge,
  Box,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
  Store,
  Person,
  CallToAction,
  Favorite,
  LocalAtm,
  Chat,
  ExitToApp,
  LockOpen,
  Menu,
  Help,
  Info,
  Contacts,
} from "@material-ui/icons";
import { IoNewspaperSharp, RiGitRepositoryPrivateFill } from "react-icons/all";
import React, { useState, useEffect } from "react";
//rr
import { useSelector, useDispatch } from "react-redux";

//action
import { setCurrentUser } from "../../Redux/actions/User/User.action";

import { Link, useHistory } from "react-router-dom";
import icon_search from "../../assets/icons/Icon-search.png";
import { SearchPage } from '../../pages/SearchPage/SearchPage'
//Icons
import icon_cart from "../../assets/icons/Icon-shopping-cart.png";
import {
  cartListByDevice,
  cartListByUser,
} from "../../Redux/actions/Cart/Cart.action";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: { flexGrow: 1 },
  drawerPaper: {
    width: "74%",
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Header(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const selectLogo = useSelector((state) => state.logoCaption.selectLogoCap);
  const [openMenu, setOpenMenu] = useState(false);
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const theme = useTheme();
  const cartList = useSelector((store) => store?.cart?.cartList?.response);
  const user = useSelector((state) => state?.user?.currentUser);
  const pageInfo = useSelector((state) => state?.pageInfo);

  const deviceId = localStorage.getItem("deviceId");

  useEffect(() => {
    user
      ? dispatch(cartListByUser(user._id))
      : dispatch(cartListByDevice(deviceId));
  }, [user, deviceId, dispatch]);

  const handleLogout = async () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem('addressId')
      window.location.href = "/";
      dispatch(setCurrentUser());
    } catch (error) {
      console.log(error);
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Divider />
        <div className="display_flex__align_justify">
          <div className="display_flex__align">
            {selectLogo && selectLogo.ilc_icon && (
              <img
                src={selectLogo.ilc_icon}
                alt="icon"
                width={40}
                style={{ margin: "0 6% 0 5%" }}
              />
            )}
            {selectLogo && selectLogo.ilc_logo && (
              <img src={selectLogo.ilc_logo} alt="icon" width={100} />
            )}
          </div>
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => setOpenMenu(false)}>
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
        </div>
        <Divider />
        <List>
          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to="/shop-by-category"
              onClick={() => setOpenMenu(false)}
              startIcon={<Store />}
              style={{ color: "var(--gray_color)" }}
            >
              Shop By Category
            </Button>
          </ListItem>
          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to={user ? "/my-account" : "/login-with-email-or-mobile"}
              onClick={() => setOpenMenu(false)}
              startIcon={<Person />}
              style={{ color: "var(--gray_color)" }}
            >
              My Account
            </Button>
          </ListItem>
          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to="/my-orders"
              onClick={() => setOpenMenu(false)}
              startIcon={<CallToAction />}
              style={{ color: "var(--gray_color)" }}
            >
              My Orders
            </Button>
          </ListItem>

          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to="/my-wishlist"
              onClick={() => setOpenMenu(false)}
              startIcon={<Favorite />}
              style={{ color: "var(--gray_color)" }}
            >
              My Wishlist
            </Button>
          </ListItem>
          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to={user ? "/my-cupones" : "/login-with-email-or-mobile"}
              onClick={() => setOpenMenu(false)}
              startIcon={<LocalAtm />}
              style={{ color: "var(--gray_color)" }}
            >
              My Coupons
            </Button>
          </ListItem>

          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to={user ? "/my-chats" : "/login-with-email-or-mobile"}
              onClick={() => setOpenMenu(false)}
              startIcon={<Chat />}
              style={{ color: "var(--gray_color)" }}
            >
              My Chats
            </Button>
          </ListItem>
          {user ? (
            <ListItem
              style={{
                margin: 0,
                padding: 0,
                marginLeft: "15px",
                width: "auto",
              }}
            >
              <Button
                onClick={() => {
                  setOpenMenu(false);
                  handleLogout();
                }}
                startIcon={<ExitToApp />}
                style={{ color: "var(--gray_color)" }}
              >
                Log Out
              </Button>
            </ListItem>
          ) : (
            <ListItem
              style={{
                margin: 0,
                padding: 0,
                marginLeft: "15px",
                width: "auto",
              }}
            >
              <Button
                component={Link}
                to="/login-with-email-or-mobile"
                onClick={() => setOpenMenu(false)}
                startIcon={<LockOpen />}
                style={{ color: "var(--gray_color)" }}
              >
                Sign In
              </Button>
            </ListItem>
          )}
          <ListItem
            style={{ margin: 0, padding: 0, marginLeft: "15px", width: "auto" }}
          >
            <Button
              component={Link}
              to="/customer-support"
              onClick={() => setOpenMenu(false)}
              startIcon={<Help />}
              style={{ color: "var(--gray_color)" }}
            >
              Help
            </Button>
          </ListItem>
          <Box style={{ height: 20 }} />
          {!pageInfo?.loading ? (
            pageInfo?.pageInfoDetails?.status === "success" &&
            pageInfo?.pageInfoDetails?.response?.map((page) => {
              const url =
                page?.page_name?.toLowerCase()?.trim()?.split(" ")[0] +
                "-" +
                page?.page_name?.toLowerCase()?.trim()?.split(" ")[1];
              return (
                page?.status && (
                  <ListItem
                    key={page?._id}
                    style={{
                      margin: 0,
                      padding: 0,
                      marginLeft: "15px",
                      width: "auto",
                    }}
                  >
                    <Button
                      component={Link}
                      to={{
                        pathname: `/${
                          page?.page_name === "Contact" ? "contact" : url
                        }`,
                        state: page,
                      }}
                      onClick={() => setOpenMenu(false)}
                      startIcon={
                        page?.page_name === "About Us" ? (
                          <Info />
                        ) : page?.page_name === "Terms and Conditions" ? (
                          <IoNewspaperSharp />
                        ) : page?.page_name === " Privacy Policy" ? (
                          <RiGitRepositoryPrivateFill />
                        ) : page?.page_name === "Contact" ? (
                          <Contacts />
                        ) : (
                          <Help />
                        )
                      }
                      style={{ color: "var(--gray_color)" }}
                    >
                      {page?.["page_name"]}
                    </Button>
                  </ListItem>
                )
              );
            })
          ) : (
            <p>loading</p>
          )}
        </List>
      </div>
    </div>
  );


  return (
    <div style={{ flexGrow: 1, marginBottom: 56 }}>
      <Modal
             open={openSearchModal}
             onClose={() => setOpenSearchModal(false)}
            >
            <SearchPage {...{ setOpenSearchModal }} />
      </Modal>
      <HideOnScroll {...props}>
        <AppBar className="bg-pink appbar_fixed">
          <Toolbar>
            <div className="display_flex__align" style={{ flexGrow: 1 }}>
              <Menu
                onClick={() => setOpenMenu(true)}
                style={{ cursor: "pointer", fontSize: 25 }}
              />

              <img
                src={selectLogo && selectLogo.ilc_icon}
                style={{
                  marginLeft: 20,
                  width: 40,
                }}
                alt=""
              />
              {/* <img
                src={selectLogo && selectLogo.ilc_logo}
                style={{
                  marginLeft: 20,
                }}
                alt="logo"
              /> */}
            </div>

            <div className="display_flex__align">
            <img
                    onClick={() => {
                      setOpenSearchModal(true)
                    }}
                    src={icon_search}
                    alt="search"
                    width={25}
                    style={{ cursor: "pointer", marginRight: '1rem' }}
                  />
              {cartList?.length !== 0 ? (
                <Badge
                  badgeContent={cartList?.length}
                  color="secondary"
                  onClick={() => history.push("/cart")}
                >
                  <img src={icon_cart} alt="cart icon" width={25} />
                </Badge>
              ) : (
                <div
                  onClick={() => history.push("/cart")}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <img src={icon_cart} alt="cart icon" width={25} />
                </div>
              )}
              {/* <div
                onClick={() => history.push("/cart")}
                style={{ position: "relative", cursor: "pointer" }}
              >
                <img src={icon_cart} alt="cart icon" width={25} />
                {cartList?.length !== 0 && (
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      position: "absolute",
                      top: "-7px",
                      right: "-5px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      backgroundColor: "red",
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {cartList?.length}
                  </div>
                )}
              </div> */}
            </div>

            {/* <Input style={{ margin: "2% 0 0 0", borderRadius: '100px' }} fluid icon='search' placeholder='Search...' /> */}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <SwipeableDrawer
        onOpen={() => setOpenMenu(true)}
        variant="temporary"
        anchor="left"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </SwipeableDrawer>
    </div>
  );
}
