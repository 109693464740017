import {
  SET_CURRENT_USER,
  SET_COUNTRY_CODE,
  FORGOT_PASSWORD,
  USER_LOGIN,
  USER_SIGNUP,
  FETCH_USER_FULL_DETAILS,
  SEND_OTP,
  ADD_USER_ADDRESS,
  ADD_GUEST_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  COUNTRY_CODE_BY_IP,
  SELECT_ADDRESS
} from "../../constants/User.type";

const UserReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case USER_SIGNUP:
      return {
        ...state,
        signUp: action.payload,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
      };
    case FETCH_USER_FULL_DETAILS:
      return {
        ...state,
        userFullDetails: action.payload,
      };
    case SEND_OTP:
      return {
        ...state,
        sendOTP: action.payload,
      };
    case ADD_USER_ADDRESS:
      return {
        ...state,
        addUserAddress: action.payload,
      };
    case ADD_GUEST_USER_ADDRESS:
      return {
        ...state,
        addGuestUser: action.payload,
      };
    case EDIT_USER_ADDRESS:
      return {
        ...state,
        editaddress: action.payload,
      };
    case COUNTRY_CODE_BY_IP:
      return {
        ...state,
        countryByIp: action.payload,
      };
    case SELECT_ADDRESS:
      return{
        ...state,
        selectedAddress: action.payload
      }
    default:
      return state;
  }
};

export default UserReducer;
