import {
  FETCH_SHIPPING_DETAILS,
  GET_DATA_BY_SHIPPING_MATHODS,
  FETCH_SHIPPING_BY_PIN,
  SET_PIN_NUMBER,
  SET_SHIPPING_CHARGES,
  REMOVE_PIN_NUMBER,
} from "../../constants/Shipping.type";

const initialState = {
  shippingCharge: null,
  pinNumber: null,
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIPPING_DETAILS:
      return {
        ...state,
        shippingDetails: action.payload,
      };
    case GET_DATA_BY_SHIPPING_MATHODS:
      return {
        ...state,
        shippingMathodValue: action.payload,
      };
    case FETCH_SHIPPING_BY_PIN:
      return {
        ...state,
        shippingDetails: action.payload,
      };
    case SET_PIN_NUMBER:
      return {
        ...state,
        pinNumber: action.payload,
      };
    case REMOVE_PIN_NUMBER:
      return {
        ...state,
        pinNumber: null,
      };
    case SET_SHIPPING_CHARGES:
      return {
        ...state,
        shippingCharge: action.payload,
      };
    default:
      return state;
  }
};

export default shippingReducer;
