import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  CART_LIST_BY_USER,
  ADD_DEVICE_FOR_CART,
  ADD_PRODUCT_TO_DEVICE,
  CART_LIST_BY_DEVICE,
  SAVE_FOR_LETER_LIST,
} from "../../constants/Cart.type";

const cartReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        addProduct: action.payload,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        updateProduct: action.payload,
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        deleteProduct: action.payload,
      };

    case CART_LIST_BY_USER:
      return {
        ...state,
        cartList: action.payload,
      };
    case ADD_DEVICE_FOR_CART:
      return {
        ...state,
        addDevice: action.payload,
      };
    case ADD_PRODUCT_TO_DEVICE:
      return {
        ...state,
        addProductToDivice: action.payload,
      };
    case CART_LIST_BY_DEVICE:
      return {
        ...state,
        cartListByDevice: action.payload,
      };
    case SAVE_FOR_LETER_LIST:
      return {
        ...state,
        saveForLaterList: action.payload,
      };
    default:
      return state;
  }
};

export default cartReducer;
