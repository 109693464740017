import React from "react";

import { Box, GridList, GridListTile } from "@material-ui/core";
import { DesignTitleBar } from "../DesignTitleBar/DesignTitleBar";
import { DesignTextArea } from "../DesignTextArea/DesignTextArea";
import { useHistory } from "react-router-dom";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";

export const DesignSingleLineGridView = ({ design }) => {
  const history = useHistory();
  const imageItems = design["data"];
  // design["direction"] = isGridView ? "vertical" : "";
  // const isVertical = design["direction"]?.toLowerCase() === "vertical";
  // const itemWidth = isVertical ? "100%" : design["grid_width"];
  const itemHeight = design["grid_height"];
  // const direction = isVertical ? "column" : "row";
  const margin = design["margin"];
  const padding = design["padding"];
  const imageBorderRadius = design["image_border_radius"];
  const textAreaLocation = design["grid_text_area_location"]?.toLowerCase();

  const isBackgroundImage =
    design["grid_background_image"].length === 0 ? false : true;
  const cols = design["cross_axis_count"];
  const itemGap = design["item_gap"];

  const itemBorderColor = design["grid_border_color"];
  const itemBorderWidth = design["grid_border_width"];

  return (
    <div key={design['_id']} style={{  backgroundColor: isBackgroundImage
      ? "none"
      : design["grid_background_color"],  backgroundImage: isBackgroundImage
      ? `url(${design["grid_background_image"]})`
      : "none",}} >
    <div
      style={{
       
        marginTop: margin["margin_top"],
        marginBottom: margin["margin_bottom"],
        marginLeft: margin["margin_left"],
        marginRight: margin["margin_right"],

        paddingTop: padding["padding_top"],
        paddingBottom: padding["padding_bottom"],
        paddingLeft: padding["padding_left"],
        paddingRight: padding["padding_right"],

       
      }}
    >
      {design["showTitleBar"] === true ? (
        <DesignTitleBar {...{ design }} />
      ) : (
        <Box />
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          overflow: "hidden",
        }}
      >
        <GridList
          cols={cols}
          spacing={0}
          style={{
            flexWrap: "wrap",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          className="hide-scrollbar2"
        >
          {imageItems.map((e, index) => {
            const isLastItem = imageItems.length > 1 ? (index + 1) % 2 === 0 : true;

            const verticalGap = imageItems.length % 2 === 0 ? imageItems.length - 2 : imageItems.length - 1

            return (
              <GridListTile
              key={e['_id']}
                style={{
                  position: "relative",
                  height: itemHeight,
                  paddingRight: isLastItem ? "0px" : itemGap,
                  paddingBottom: (index + 1)  > verticalGap ? '0px' :  itemGap,
                }}
              >
                {textAreaLocation === "top" ? (
                  <DesignTextArea {...{ design, texts: e["texts"] }} />
                ) : (
                  <Box />
                )}
                <Box style={{ position: "relative", marginBottom: "-4.44px" }}>
                  <img
                    onClick={() => {
                      HandleOnDesignClick(e, history);
                    }}
                    src={e["img"]}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: itemHeight,
                      borderTopLeftRadius:
                        imageBorderRadius["image_border_top_left"],
                      borderTopRightRadius:
                        imageBorderRadius["image_border_top_right"],
                      borderBottomLeftRadius:
                        imageBorderRadius["image_border_bottom_left"],
                      borderBottomRightRadius:
                        imageBorderRadius["image_border_bottom_right"],
                      border: design["is_grid_border"]
                        ? `${itemBorderWidth}px solid ${itemBorderColor}`
                        : "none",
                    }}
                    alt={""}
                  />
                  {textAreaLocation === "overlay" ? (
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                      }}
                    >
                      <DesignTextArea {...{ design, texts: e["texts"] }} />
                    </div>
                  ) : (
                    <div />
                  )}
                </Box>
                {textAreaLocation === "bottom" ? (
                  <DesignTextArea {...{ design, texts: e["texts"] }} />
                ) : (
                  <div />
                )}
              </GridListTile>
            );
          })}
        </GridList>
      </div>
    </div>
    </div>
  );
};
