import { loginGuestuser } from "../api/api";

export const signInGuestUser = async (userId, countryCode) => {
  console.log(countryCode);
  try {
    const variables = {
      send_value: userId,
      countrycode: countryCode,
    };
    const { data } = await loginGuestuser(variables);
    return data;
  } catch (error) {
    console.log(error);
  }
};
