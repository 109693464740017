import { Grid } from "@material-ui/core";
import React from "react";

import { Box } from "@material-ui/core";
import { DesignTitleBar } from "../DesignTitleBar/DesignTitleBar";
import { DesignTextArea } from "../DesignTextArea/DesignTextArea";
import { useHistory } from "react-router-dom";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";

export const DesignGridView3Item = ({ design }) => {
  const history = useHistory();
  const imageItems = design["data"];

  const itemHeight = design["grid_height"];
  const margin = design["margin"];
  const padding = design["padding"];
  // const imageBorderRadius = design["image_border_radius"];
  const textAreaLocation = design["grid_text_area_location"]?.toLowerCase();
  const isBackgroundImage =
    design["grid_background_image"].length === 0 ? false : true;
  // const cols = 2;
  // const itemGap = design["item_gap"];

  // const itemBorderColor = design["grid_border_color"];
  // const itemBorderWidth = design["grid_border_width"];

  const renderImageTile = (e, itemHeight) => {
    const img = e["img"];
    return (
      <div key={design['_id']} style={{ display: "grid", placeContent: "center" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            padding: "10px",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          {textAreaLocation === "top" ? (
            <DesignTextArea {...{ design, texts: e["texts"] }} />
          ) : (
            <Box />
          )}
          <div style={{ position: "relative" }}>
            <img
              onClick={() => {
                HandleOnDesignClick(e, history);
              }}
              src={img}
              alt=""
              style={{
                // flexGrow: 1,
                height: itemHeight,
                objectFit: "fill",
                // padding: "15px",
              }}
            />
            {textAreaLocation === "overlay" ? (
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 2,
                }}
              >
                <DesignTextArea {...{ design, texts: e["texts"] }} />
              </div>
            ) : (
              <div />
            )}
          </div>
          {textAreaLocation === "bottom" ? (
            <DesignTextArea {...{ design, texts: e["texts"] }} />
          ) : (
            <Box />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: isBackgroundImage
          ? "none"
          : design["grid_background_color"],
        marginTop: margin["margin_top"],
        marginBottom: margin["margin_bottom"],
        marginLeft: margin["margin_left"],
        marginRight: margin["margin_right"],

        paddingTop: padding["padding_top"],
        paddingBottom: padding["padding_bottom"],
        paddingLeft: padding["padding_left"],
        paddingRight: padding["padding_right"],

        backgroundImage: isBackgroundImage
          ? `url(${design["grid_background_image"]})`
          : "none",
      }}
    >
      {/* <img src={imageUrl} alt="" /> */}
      {design["showTitleBar"] === true ? (
        <DesignTitleBar {...{ design }} />
      ) : (
        <Box />
      )}

      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={8}
          style={{
            height: "100%",
            borderRight: `${design["item_gap"]}px solid #eeeeee`,
          }}
        >
          {renderImageTile(design["data"][0], `${itemHeight}px`)}
        </Grid>
        <Grid item xs={4} style={{ height: "100%" }} container>
          <Grid
            item
            xs={12}
            style={{
              height: "50%",
              borderBottom: `${design["item_gap"]}px solid #eeeeee`,
            }}
          >
            {renderImageTile(design["data"][1], `${itemHeight / 2}px`)}
          </Grid>
          <Grid item xs={12} style={{ height: "50%" }}>
            {renderImageTile(design["data"][2], `${itemHeight / 2}px`)}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
