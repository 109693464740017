import React from "react";

import { Box, GridList, GridListTile } from "@material-ui/core";
import { DesignTitleBar } from "../DesignTitleBar/DesignTitleBar";
import { DesignTextArea } from "../DesignTextArea/DesignTextArea";
import { useHistory } from "react-router-dom";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";

export const DesignListView = ({ design }) => {
  const history = useHistory();
  // const isGridView = design["design_type"]?.toLowerCase() === "gridview";
  const imageItems = design["data"];
  // design["direction"] = isGridView ? "vertical" : "";
  const isVertical = design["direction"]?.toLowerCase() === "vertical";
  const itemWidth = isVertical ? "100%" : design["grid_width"];
  const itemHeight = design["grid_height"];
  const direction = isVertical ? "column" : "row";
  const margin = design["margin"];
  const padding = design["padding"];
  const imageBorderRadius = design["image_border_radius"];
  const textAreaLocation = design["grid_text_area_location"]?.toLowerCase();
  const itemBorderColor = design["grid_border_color"];
  const itemBorderWidth = design["grid_border_width"];

  const isBackgroundImage =
    design["grid_background_image"].length === 0 ? false : true;

  return (
    <div
    key={design['_id']}
      style={{
        backgroundColor: isBackgroundImage
          ? "none"
          : design["grid_background_color"],
        marginTop: margin["margin_top"],
        marginBottom: margin["margin_bottom"],
        marginLeft: margin["margin_left"],
        marginRight: margin["margin_right"],

        paddingTop: padding["padding_top"],
        paddingBottom: padding["padding_bottom"],
        paddingLeft: padding["padding_left"],
        paddingRight: padding["padding_right"],

        backgroundImage: isBackgroundImage
          ? `url(${design["grid_background_image"]})`
          : "none",
        backgroundSize: "cover",
      }}
    >
      {design["showTitleBar"] === true ? (
        <DesignTitleBar {...{ design }} />
      ) : (
        <Box />
      )}  
      <GridList
        cols={2}
        spacing={0}
        style={{
          flexDirection: direction,
          flexWrap: isVertical ? "wrap" : "nowrap",
          transform: "translateZ(0)",
          margin: "0px !important",
          padding: "0px !important",
        }}
        className="hide-scrollbar2"
      >
        {imageItems.map((e, index) => {
          return (
            <GridListTile
            key={e['_id']}
              style={{
                width: itemWidth,
                height: "100%",
                marginRight: isVertical ? 0 : design["item_gap"],
                marginBottom: isVertical ? design["item_gap"] : 0,
                position: "relative",
              }}
            >
              {textAreaLocation === "top" ? (
                <DesignTextArea {...{ design, texts: e["texts"] }} />
              ) : (
                <Box />
              )}
              <div style={{ position: "relative", marginBottom: "-4.44px" }}>
                <img
                  onClick={() => {
                    HandleOnDesignClick(e, history);
                  }}
                  src={e["img"]}
                  style={{
                    objectFit: "fill",
                    width: "100%",
                    height: itemHeight,

                    borderTopLeftRadius:
                      imageBorderRadius["image_border_top_left"],
                    borderTopRightRadius:
                      imageBorderRadius["image_border_top_right"],
                    borderBottomLeftRadius:
                      imageBorderRadius["image_border_bottom_left"],
                    borderBottomRightRadius:
                      imageBorderRadius["image_border_bottom_right"],
                    border: `${itemBorderWidth}px solid ${itemBorderColor}`,
                  }}
                  alt={""}
                />
                {textAreaLocation === "overlay" ? (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 2,
                      margin: design["grid_border_width"],
                    }}
                  >
                    <DesignTextArea {...{ design, texts: e["texts"] }} />
                  </div>
                ) : (
                  <div />
                )}
              </div>
              {textAreaLocation === "bottom" ? (
                <DesignTextArea {...{ design, texts: e["texts"] }} />
              ) : (
                <div />
              )}
            </GridListTile>
          );
        })}
      </GridList>
    </div>
  );
};
