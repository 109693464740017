import React from "react";

import Slick from "react-slick";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";

import { useHistory } from "react-router-dom";

export const DesignCarosuel = ({ design }) => {
  const history = useHistory();
  const imageItems = design["data"];
  const margin = design["margin"];
  const padding = design["padding"];
  const showArrow = design["carosel_arrows"];
  const duration = design["carosel_duration"];

  const settings = {
    dots: true,
    infinite: true,
    speed: duration,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  console.log("CAROSUEL_DESIGN: ");
  console.log(design);

  return (
    <div
    key={design['_id']}
      style={{
        backgroundColor: "#ffffff",
        marginTop: margin["margin_top"],
        marginBottom: margin["margin_bottom"],
        marginLeft: margin["margin_left"],
        marginRight: margin["margin_right"],
        paddingTop: padding["padding_top"],
        paddingBottom: padding["padding_bottom"],
        paddingLeft: padding["padding_left"],
        paddingRight: padding["padding_right"],
      }}
    >
      <Slick arrows={showArrow} {...settings}>
        {imageItems.map((e, index) => {
          return (
            <div key={e['_id']} className="lol">
              <img
                style={{
                  // borderColor: design["grid_border_color"],
                  // borderWidth: design["grid_border_width"],
                  border: `${design["grid_border_width"]}px solid ${design["grid_border_color"]}`,
                  width: "100%",
                  height: design["grid_height"],
                  objectFit: "fill",
                  borderRadius: design["grid_border_radius"],
                }}
                src={e["img"]}
                onClick={() => {
                  HandleOnDesignClick(e, history);
                }}
                alt="some"
              />
            </div>
          );
        })}
      </Slick>
    </div>
  );
};
