import React from "react";

export const DesignTextArea = ({ design, texts }) => {
  const borderRadius = design["text_area_border_radius"];
  const padding = design["text_padding"];
  const bgColor = design["grid_text_area_bg_color"];

  return (
    <div
      style={{
        backgroundColor:
          bgColor === "transparent"
            ? "transparent"
            : design["grid_text_area_bg_color"],
        opacity: design["grid_text_area_opacity"],
        borderTopLeftRadius: borderRadius["text_area_border_top_left"],
        borderTopRightRadius: borderRadius["text_area_border_top_right"],
        borderBottomLeftRadius: borderRadius["text_area_border_bottom_left"],
        borderBottomRightRadius: borderRadius["text_area_border_bottom_right"],

        paddingTop: padding["text_padding_top"],
        paddingRight: padding["text_padding_right"],
        paddingBottom: padding["text_padding_bottom"],
        paddingLeft: padding["text_padding_left"],

        width: "100%",

        // marginBottom: design["grid_border_width"],
      }}
    >
      {texts.map((e, index) => {
        console.log(`FONT_SIZE: ${e["text_font_size"]}`);
        return (
          <p
            style={{
              color: e["text_color"],
              fontSize: e["text_font_size"],
              textAlign: design["text_align"],
              padding: "7px",
            }}
          >
            {e["text_value"]}
          </p>
        );
      })}
    </div>
  );
};
