import { GridList, GridListTile, Paper } from "@material-ui/core";
import React from "react";
import { ProductTile3 } from "../Product/ProductTile3/ProductTile3";

export const DesignHorizontalListProductView = ({ design }) => {
  const allProducts = design["product_data"];
  const containerTitleDesign = design["container_title_design"];
  const containerSubtitleDesign = design["container_subtitle_design"];

  return (
    <Paper key={design['_id']} elevation={5} style={{ margin: "5px", padding: "5px" }}>
      {design["showTitleBar"] ? (
        <div style={{ marginLeft: "15px" }}>
          <div
            style={{
              fontSize: containerTitleDesign["container_title_fontSize"],
              color: containerTitleDesign["container_title_color"],
            }}
          >
            {containerTitleDesign["container_title_text"]}
          </div>
          <div
            style={{
              fontSize: containerSubtitleDesign["container_subtitle_fontSize"],
              color: containerSubtitleDesign["container_subtitle_color"],
              marginTop: "5px",
            }}
          >
            {containerTitleDesign["container_subtitle_text"]}
          </div>
        </div>
      ) : (
        <div />
      )}

      <div
        className=""
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          overflow: "hidden",
        }}
      >
        <GridList
          cols={1.7}
          style={{
            backgroundColor: "#EEEEEE",
            flexWrap: "nowrap",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          spacing={2}
          cellHeight="100%"
        >
          {allProducts.map((e) => (
            <GridListTile key={e}>
              <ProductTile3
                {...{
                  imageUrl: e["cover_image"],
                  productTitle: e["product_name"],
                  item: e,
                }}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </Paper>
  );
};
