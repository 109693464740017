import {
  FETCH_ALL_LOGOCATION_LIST,
  FETCH_ALL_COUNTY_LIST,
  SELECT_LOGO_CAPTION,
  SELECTED_BASE_COUNTRY,
  // LOADING_TRUE,
} from "../../constants/LogoCaption.type";

const LogoCaptionReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALL_LOGOCATION_LIST:
      return {
        ...state,
        logoCaption: action.payload,
      };
    case FETCH_ALL_COUNTY_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case SELECT_LOGO_CAPTION:
      return {
        ...state,
        selectLogoCap: action.payload,
      };
    case SELECTED_BASE_COUNTRY:
      return {
        ...state,
        selectedBaseCountry: action.payload,
      };
    default:
      return state;
  }
};

export default LogoCaptionReducer;
