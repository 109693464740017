export const FETCH_ALL_PRODUCT = "FETCH_ALL_PRODUCT";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";
export const FETCH_PRODUCT_WISE_CATEGORY = "FETCH_PRODUCT_WISE_CATEGORY";
export const FETCH_PRODUCT_WISE_CATEGORY_SUCCESS =
  "FETCH_PRODUCT_WISE_CATEGORY_SUCCESS";
export const FETCH_PRODUCT_WISE_CATEGORY_FALIUR =
  "FETCH_PRODUCT_WISE_CATEGORY_FALIUR";
export const GET_PRODUCT_OFFER = "GET_PRODUCT_OFFER";
export const APPLY_OFFER = "APPLY_OFFER";
export const SIMILER_PRODUCTS = "SIMILER_PRODUCTS";
export const PRODUCT_BY_ID = "PRODUCT_BY_ID";
export const FETCH_SET_PRODUCT = "FETCH_SET_PRODUCT";
export const FETCH_SET_PRODUCT_SUCCESS = "FETCH_SET_PRODUCT_SUCCESS";
export const FETCH_SET_PRODUCT_FALIUR = "FETCH_SET_PRODUCT_FALIUR";
export const SAVE_PRODUCT_FROM_PAGINATION = 'SAVE_PRODUCT_FROM_PAGINATION'
export const FETCH_CATEGORY_FIRST_PRODUCT = 'FETCH_CATEGORY_FIRST_PRODUCT'
export const FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS = 'FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS'
export const FETCH_CATEGORY_FIRST_PRODUCT_FALIUR = 'FETCH_CATEGORY_FIRST_PRODUCT_FALIUR'
export const FETCH_CATEGORY_SECOND_PRODUCT = 'FETCH_CATEGORY_SECOND_PRODUCT'
export const FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS = 'FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS'
export const FETCH_CATEGORY_SECOND_PRODUCT_FALIUR = 'FETCH_CATEGORY_SECOND_PRODUCT_FALIUR'
export const SUB_CAT_PRODUCT = 'SUB_CAT_PRODUCT'
export const SUB_CAT_PRODUCT_SUCCESS = 'SUB_CAT_PRODUCT_SUCCESS'
export const SUB_CAT_PRODUCT_FALIUR = 'SUB_CAT_PRODUCT_FALIUR'
export const SAVE_FIRST_CAT_PRODUCT = 'SAVE_FIRST_CAT_PRODUCT'
export const SAVE_SECOND_CAT_PRODUCT = 'SAVE_SECOND_CAT_PRODUCT'
export const SAVE_SUB_CAT_PRODUCT = 'SAVE_SUB_CAT_PRODUCT'

