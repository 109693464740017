import {
  Grid,
  TextField,
  colors,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import * as icons from "@material-ui/icons";
import React from "react";
import { useHistory } from 'react-router-dom'

export const SearchPage = ({ setOpenSearchModal }) => {
  const history = useHistory()
  const renderDiscoverMore = () => {
    return (
      <Paper style={{ margin: "7px", padding: "7px" }}>
        <Typography
          style={{ fontSize: "16px", marginBottom: "5px", fontWeight: "500" }}
        >
          Discover More
        </Typography>
        <div>
          {[
            {
              text: "Mobiles",
            },
            {
              text: "Shoes",
            },
            {
              text: "T Shirt",
            },
            {
              text: "Laptops",
            },
            {
              text: "Watches",
            },
            {
              text: "TV",
            },
            {
              text: "Sarees",
            },
            {
              text: "Bluetooth",
            },
            {
              text: "Fridge",
            },
          ].map((item) => {
            return (
              <Button
                style={{ margin: "5px", textTransform: "none" }}
                variant="outlined"
                color="primary"
              >
                {item["text"]}
              </Button>
            );
          })}
        </div>
      </Paper>
    );
  };

  return (
    <div
      style={{ height: "100vh", width: "100vw", backgroundColor: "#ffffff" }}
    >
      <Paper elevation={0}>
        <Grid
          style={{ padding: "7px" }}
          container
          justify="space-evenly"
          alignItems="center"
        >
          <Grid item xs={1}>
            <icons.ArrowBack
              onClick={() => {
                // history.goBack()
                setOpenSearchModal(false);
              }}
              style={{
                color: colors.grey[500],
              }}
              fontSize="large"
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              inputProps={{
                style: {
                  fontSize: "16px",
                  borderBottom: "0px solid #ffffff",
                  outline: "none",
                  padding: "10px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Search for product brand and more"
            />
          </Grid>
          <Grid item xs={1}>
            <icons.MicRounded
              style={{
                color: colors.grey[500],
              }}
              fontSize="large"
            />
          </Grid>
        </Grid>
      </Paper>
      {renderDiscoverMore()}
      {/* <div>Sraech Page</div> */}
    </div>
  );
};
