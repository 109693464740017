import { FETCH_TRAMSAND_CONDITIONS } from "../../constants/TramsAndConditions.type";

const TramsAndConditionsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_TRAMSAND_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };
    default:
      return state;
  }
};

export default TramsAndConditionsReducer;
