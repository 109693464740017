import {
  FETCH_ALL_PAGE_INFO,
  FETCH_ALL_PAGE_INFO_FALIUR,
  FETCH_ALL_PAGE_INFO_SUCCESS,
} from "../../constants/PageInfo.type";

const initialState = {
  loading: true,
  pageInfoDetails: null,
};

const pageInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PAGE_INFO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_PAGE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        pageInfoDetails: action.payload,
      };
    case FETCH_ALL_PAGE_INFO_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default pageInfoReducer;
