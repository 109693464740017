export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_COUNTRY_CODE = "SET_COUNTRY_CODE";
export const USER_LOGIN = "USER_LOGIN";
export const USER_SIGNUP = "USER_SIGNUP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FETCH_USER_FULL_DETAILS = "FETCH_USER_FULL_DETAILS";
export const SEND_OTP = "SEND_OTP";
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const ADD_GUEST_USER_ADDRESS = "ADD_GUEST_USER_ADDRESS";
export const SELECT_ADDRESS = "SELECT_ADDRESS";
export const EDIT_USER_ADDRESS = "EDIT_USER_ADDRESS";
export const COUNTRY_CODE_BY_IP = "COUNTRY_CODE_BY_IP";