import {
  FETCH_ALL_PRODUCT,
  FETCH_SINGLE_PRODUCT,
  FETCH_PRODUCT_WISE_CATEGORY,
  FETCH_PRODUCT_WISE_CATEGORY_SUCCESS,
  FETCH_PRODUCT_WISE_CATEGORY_FALIUR,
  GET_PRODUCT_OFFER,
  APPLY_OFFER,
  SIMILER_PRODUCTS,
  PRODUCT_BY_ID,
  FETCH_SET_PRODUCT,
  FETCH_SET_PRODUCT_SUCCESS,
  FETCH_SET_PRODUCT_FALIUR,
  SAVE_PRODUCT_FROM_PAGINATION,
  FETCH_CATEGORY_FIRST_PRODUCT,
  FETCH_CATEGORY_FIRST_PRODUCT_FALIUR,
  FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS,
  FETCH_CATEGORY_SECOND_PRODUCT,
  FETCH_CATEGORY_SECOND_PRODUCT_FALIUR,
  FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS ,
  SAVE_FIRST_CAT_PRODUCT,
  SAVE_SECOND_CAT_PRODUCT,
  SUB_CAT_PRODUCT,
  SUB_CAT_PRODUCT_FALIUR,
  SUB_CAT_PRODUCT_SUCCESS,
  SAVE_SUB_CAT_PRODUCT
} from "../../constants/Product.type";

const initialState = {
  loading: true,
  categoryWiseProducts: null,
  comboProductLoading: true,
  category1Product:{
    loading: true,
    product: null
  },
  category3Product:{
    loading: true,
    product: null
  },
  subCatProduct:{
    loading: true,
    product: null
  }
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PRODUCT:
      return {
        ...state,
        productList: action.payload,
      };
    case FETCH_SINGLE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case FETCH_PRODUCT_WISE_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_WISE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryWiseProducts: action.payload,
      };
    case FETCH_PRODUCT_WISE_CATEGORY_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CATEGORY_FIRST_PRODUCT:
      return{
        ...state,
        category1Product:{
          loading: true,
        }
      }
    case FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS:
      return{
        ...state,
        category1Product:{
          loading: false,
          product: action.payload
        }
      }
    case FETCH_CATEGORY_FIRST_PRODUCT_FALIUR:
      return{
        ...state,
        category1Product:{
          loading: false,
          error: action.payload
        }
      }
    case FETCH_CATEGORY_SECOND_PRODUCT:
      return{
        ...state,
        category3Product:{
          loading: true
        }
      }
    case FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS:
      return{
        ...state,
        category3Product:{
          loading: false,
          product: action.payload
        }
      }
    case FETCH_CATEGORY_SECOND_PRODUCT_FALIUR:
      return{
        ...state,
        category3Product:{
          loading: false,
          error: action.payload
        }
      }
    case SUB_CAT_PRODUCT:
      return{
        ...state,
        subCatProduct:{
          loading: true
        }
      }
    case SUB_CAT_PRODUCT_SUCCESS:
      return{
        ...state,
        subCatProduct:{
          loading: false,
          product: action.payload
        }
      }
    case SUB_CAT_PRODUCT_FALIUR:
      return{
        ...state,
        subCatProduct:{
          loading: false,
          error: action.payload
        }
      }
    case GET_PRODUCT_OFFER:
      return {
        ...state,
        productOffer: action.payload,
      };
    case APPLY_OFFER:
      return {
        ...state,
        applyOffer: action.payload,
      };
    case SIMILER_PRODUCTS:
      return {
        ...state,
        similerProducts: action.payload,
      };
    case PRODUCT_BY_ID:
      return {
        ...state,
        productById: action.payload,
      };
    case FETCH_SET_PRODUCT:
      return {
        ...state,
        comboProductLoading: true,
      };
    case FETCH_SET_PRODUCT_SUCCESS:
      return {
        ...state,
        settAndComboProduct: action.payload,
        comboProductLoading: false,
      };
    case FETCH_SET_PRODUCT_FALIUR:
      return {
        ...state,
        error: action.payload,
        comboProductLoading: false,
      };
    case SAVE_PRODUCT_FROM_PAGINATION:
      return{
        ...state,
        categoryWiseProducts:{
          ...state?.categoryWiseProducts,
          response: state?.categoryWiseProducts?.response?.concat(action.payload)
        }
      }
    case SAVE_FIRST_CAT_PRODUCT:
      return{
        ...state,
        category1Product:{
          ...state.category1Product,
          product:{
            ...state.category1Product.product,
            response: state?.category1Product?.product?.response?.concat(action.payload)
          }
        }
      }
      case SAVE_SECOND_CAT_PRODUCT:
      return{
        ...state,
        category3Product:{
          ...state.category3Product,
          product:{
            ...state.category3Product.product,
            response: state?.category3Product?.product?.response?.concat(action.payload)
          }
        }
      }
    case SAVE_SUB_CAT_PRODUCT:
      return{
        ...state,
        subCatProduct:{
          ...state.subCatProduct,
          product:{
            ...state.subCatProduct.product,
            response: state?.subCatProduct?.product?.response?.concat(action.payload)
          }
        }
      }
    default:
      return state;
  }
};

export default productReducer;
