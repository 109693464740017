export const FETCH_ALL_CATEGORIES = "FETCH_ALL_CATEGORIES";
export const FETCH_SINGLE_CATEGORIES = "FETCH_SINGLE_CATEGORIES";
export const FETCH_CHILD_CATEGORIES = "FETCH_CHILD_CATEGORIES";
export const FETCH_CHILD_CATEGORIES_SUCCESS = "FETCH_CHILD_CATEGORIES_SUCCESS";
export const FETCH_CHILD_CATEGORIES_FALIUR = "FETCH_CHILD_CATEGORIES_FALIUR";
export const FETCH_FEATURED_PRODUCT_BY_CAT = "FETCT_FEATURED_PRODUCT_BY_CAT";
export const FETCH_SINGLE_CATEGORIES_SUCCESS =
  "FETCH_SINGLE_CATEGORIES_SUCCESS";
export const FETCH_SINGLE_CATEGORIES_FALIUR = "FETCH_SINGLE_CATEGORIES_FALIUR";
export const FETCH_FEATURED_PRODUCT_BY_CAT_SUCCESS =
  "FETCT_FEATURED_PRODUCT_BY_CAT_SUCCESS";
export const FETCH_FEATURED_PRODUCT_BY_CAT_FALIUR =
  "FETCT_FEATURED_PRODUCT_BY_CAT_FALIUR";
export const FETCH_FEATURED_PRODUCT = 'FETCH_FEATURED_PRODUCT'
export const FETCH_FEATURED_PRODUCT_SUCCESS = 'FETCH_FEATURED_PRODUCT_SUCCESS'
export const FETCH_FEATURED_PRODUCT_FALIUR = 'FETCH_FEATURED_PRODUCT_FALIUR'
export const FETCH_PARENT_CATEGORY = 'FETCH_PARENT_CATEGORY'
export const FETCH_PARENT_CATEGORY_SUCCESS = 'FETCH_PARENT_CATEGORY_SUCCESS'
export const FETCH_PARENT_CATEGORY_FALIUR = 'FETCH_PARENT_CATEGORY_FALIUR'