import { CREATE_A_ORDER, ORDER_LIST } from "../../constants/Order.type";

const orderReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_A_ORDER:
      return {
        ...state,
        createOrder: action.payload,
      };
    case ORDER_LIST:
      return {
        ...state,
        orderList: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
