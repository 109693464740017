import firebase from "firebase/app";
import "firebase/auth";

const config =  {
  apiKey: "AIzaSyAh2v9sYDbHK5deSXU4Qgak-2zsfE1Yufw",
  authDomain: "paramafashion-b4565.firebaseapp.com",
  projectId: "paramafashion-b4565",
  storageBucket: "paramafashion-b4565.appspot.com",
  messagingSenderId: "102873581563",
  appId: "1:102873581563:web:157d2c150432f2725dad5e",
  measurementId: "G-S25N0WP87R"
};

const app = firebase.initializeApp(config);

export const auth = app.auth();

export default firebase;
