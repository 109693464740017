import {
  FETCH_HOMEPAGE_BANNER,
  FETCH_HOMEPAGE_DESIGN,
  FETCH_HOMEPAGE_DESIGN_SUCCESS,
  FETCH_HOMEPAGE_DESIGN_FALIUR,
  FETCH_CATEGORY_FIRST,
  FETCH_CATEGORY_FIRST_FALIUR,
  FETCH_CATEGORY_FIRST_SUCCESS,
  FETCH_CATEGORY_SECOND,
  FETCH_CATEGORY_SECOND_FALIUR,
  FETCH_CATEGORY_SECOND_SUCCESS,
  FETCH_CATEGORY_BANNER,
  FETCH_CATEGORY_BANNER_FALIUR,
  FETCH_CATEGORY_BANNER_SUCCESS
} from "../../constants/Home.type";

const initialState = {
  loading: true,
  homePageDesign: null,
  design: [],
  category1:{
    banner: null,
    loading: true
  },
  category2:{
    banner: null,
    loading: true
  },
  category:{
    banner: null,
    loading: true
  }
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOMEPAGE_BANNER:
      return {
        ...state,
        homePageBanner: action.payload,
      };
    case FETCH_HOMEPAGE_DESIGN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HOMEPAGE_DESIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        homePageDesign: action.payload,
        design: [...state.design, action.payload.design.map((des) => des)],
      };
    case FETCH_HOMEPAGE_DESIGN_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CATEGORY_FIRST:
      return{
        ...state,
        category1:{
          loading: true
        } 
      }
    case FETCH_CATEGORY_FIRST_SUCCESS:
      return{
        ...state,
        category1:{
          loading: false,
          banner: action.payload
        }
      }
    case FETCH_CATEGORY_FIRST_FALIUR:
      return{
        ...state,
        category1:{
          loading: false,
          error: action.payload
        }
      }
    case FETCH_CATEGORY_SECOND:
      return{
        ...state,
        category2:{
          loading: true,
        }
      }
    case FETCH_CATEGORY_SECOND_SUCCESS:
      return{
        ...state,
        category2:{
          loading: false,
          banner: action.payload
        }
      }
    case FETCH_CATEGORY_SECOND_FALIUR:
      return{
        ...state,
        category2:{
          loading: false,
          error: action.payload
        }
      }
    case FETCH_CATEGORY_BANNER:
      return{
        ...state,
        category:{
          loading: true
        }
      }
    case FETCH_CATEGORY_BANNER_SUCCESS:
      return{
        ...state,
        category:{
          loading: false,
          banner: action.payload
        }
      }
    case FETCH_CATEGORY_BANNER_FALIUR:
      return{
        ...state,
        category:{
          loading: false,
          error: action.payload
        }
      }
    default:
      return state;
  }
};

export default homeReducer;
