import { sendOTP } from "../api/api";
// import { generateOTP } from "./generateOTP";

export const sendOtp = async (newUser, optOTP) => {
  let { email, phone, OTP, country_code } = newUser;
  console.log(OTP);
  if (OTP.trim() === "") {
    OTP = optOTP;
  }
  let response = {};
  if (email?.trim() !== "") {
    const variables = {
      send_value: email,
      send_otp: OTP,
      send_country_code: country_code,
    };
    const { data } = await sendOTP(variables);
    console.log(data);
    response = { ...data };
  }

  // if (phone.trim() !== "") {
  //   const variables = {
  //     send_value: phone,
  //     send_otp: OTP,
  //     send_country_code: country_code,
  //   };
  //   const { data } = await sendOTP(variables);
  //   console.log(data);
  //   response = { ...data };
  // }

  return response;
};
