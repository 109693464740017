import React from "react";
import { colors, Box, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Favorite } from "@material-ui/icons";

export const ProductTile3 = ({ imageUrl, productTitle, item }) => {
  const history = useHistory();

  const countryCode = useSelector((state) => state?.user?.countryCode);

  return (
    <div>
      <Paper
        elevation={1}
        style={{
          position: "absolute",
          right: "2px",
          backgroundColor: "#ffffff",
          borderRadius: "50%",
          padding: "7px",
        }}
      >
        <Favorite
          style={{
            color: "#caced1",
            position: "relative",
            top: "3px",
            fontSize: "22px",
          }}
        />
      </Paper>
      <div
        onClick={() => history.push(`/product-details/${item["_id"]}`)}
        elevation={7}
        style={{
          // margin: "10px",
          padding: "5px",
          width: "100%",
          display: "inline-block",
          backgroundColor: "#ffffff",
        }}
      >
        <div style={{}}>
          <img
            style={{
              height: "248px",
              width: "100%",
              objectFit: "contain",
            }}
            src={imageUrl}
            alt=""
          />
        </div>

        <div style={{}}>
          <div
            style={{
              fontSize: "20px",
              lineHeight: "1",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {productTitle?.toUpperCase()}
          </div>

          <Box height="10px" />
          <div>
            <span
              style={{
                marginRight: "5px",
                fontWeight: "500",
                fontSize: "18px",
              }}
            >
              {countryCode &&
                countryCode.status !== "fail" &&
                String.fromCharCode(
                  parseInt(countryCode.response.selected_currency_symbol, 16)
                )}

              {item["price_from_buyer"]}
            </span>
            <snap style={{ marginRight: "5px", color: "grey" }}>
              <strike> {item["mrp"]} </strike>
            </snap>
            <span style={{ color: colors.green[600], fontWeight: "600" }}>
              {item["price_discount_from_buyer"]}% off
            </span>
          </div>

          <Box height="10px" />
          <div style={{ color: colors.green[600] }}>Free Delivery</div>
        </div>
      </div>
    </div>
  );
};
