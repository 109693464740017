import { Box, colors, Grid } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProductTile2 = ({ imageUrl, productTitle, item }) => {
  const history = useHistory();
  const countryCode = useSelector((state) => state?.user?.countryCode);

  return (
    <div
      onClick={() => history.push(`/product-details/${item["_id"]}`)}
      elevation={7}
      style={{
        margin: "0px",
        padding: "5px",
        backgroundColor: "#ffffff",
        marginBottom: "2px",
      }}
    >
      <Grid container>
        <Grid item xs={5} style={{ margin: 0, padding: 0 }}>
          <img
            style={{
              height: "140px",
              width: "100%",
              objectFit: "contain",
            }}
            src={imageUrl}
            alt=""
          />
        </Grid>
        <Grid item xs={7} style={{ padding: "15px" }}>
          <div
            style={{
              fontSize: "20px",
              lineHeight: "1",
            }}
          >
            {productTitle?.toUpperCase()}
          </div>

          <Box height="15px" />
          <div>
            <span
              style={{
                marginRight: "10px",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              {countryCode &&
                countryCode.status !== "fail" &&
                String.fromCharCode(
                  parseInt(countryCode.response.selected_currency_symbol, 16)
                )}

              {item["price_from_buyer"]}
            </span>
            <snap style={{ marginRight: "10px", color: "grey" }}>
              <strike>{item["mrp"]}</strike>
            </snap>
            <span style={{ color: colors.green[600], fontWeight: "600" }}>
              {item["price_discount_from_buyer"]}% off
            </span>
          </div>

          <Box height="15px" />
          <div style={{ color: colors.green[600] }}>Free Delivery</div>
        </Grid>
      </Grid>
    </div>
  );
};
