import axios from "axios";

const url = "https://beauty-247-backend-g5whtncwfa-uc.a.run.app/v1.0/api";
// "http://localhost:8080/v1.0/api";
// "https://api.paramafashion.com/v1.0/api";
//  "https://167.71.172.131/v1.0/api";

//CountryList
export const getCountryList = () => axios.get(`${url}/selected_country_list`);

//LogoList
export const getLogoCaptionList = () =>
  axios.get(`${url}/icon_logo_caption_list`);

//Products
export const getProductList = () => axios.get(`${url}/product_list`);

//product by id
export const productbyid = (id) => axios.post(`${url}/product_by_id`, id);

//getCategory wise products
export const getcategoryWiseProduct = (catId, page, limit) =>
  axios.post(`${url}/product_by_cat_id?page=${page}&limit=${limit}`, catId);

//Category
export const getallcategory = () => axios.get(`${url}/get_all_categories`);

export const getParentCategoryList = () => axios.get(`${url}/get_all_main_category`);

export const getSubCategoryList = (catId) =>
  axios.get(`${url}/sub_cat_by_any_collection_id/${catId}`);

//sendOtp
export const sendOTP = (newUser) =>
  axios.post(`${url}/send_otp_by_email_sms`, newUser);

//userExistingCheck
export const userExistingChecking = (user) =>
  axios.post(`${url}/get_user_type`, user);

//user SignUp
export const userRegistration = (newUser) =>
  axios.post(`${url}/user_signup`, newUser);

//user Login
export const userLogin = (user) => axios.post(`${url}/user_login`, user);

export const userPasswordValidation = (userData) =>
  axios.post(`${url}/check_password_by_user_id`, userData);

export const checkPhoneOrEmailexist = (body) => axios.post(`${url}/check_user_phone_or_email_already_exists`, body) 

//forgot password
export const forgotpassword = (user) =>
  axios.post(`${url}/forgot_password`, user);

//userDetailsByIp
export const userDetailsByIp = () => axios.get("https://ipapi.co/json/");

//getCountryCodeByIp
export const getCountryCodeByIp = (country) =>
  axios.post(`${url}/my_ip`, country);

//add products to cart
export const addProductToCart = (product) =>
  axios.post(`${url}/product_add_cart`, product);

export const updateProductfromCart = (data) =>
  axios.post(`${url}/cart_update`, data);

export const deleteProductfromCart = (cartId) =>
  axios.post(`${url}/cart_remove`, cartId);

//cart list by user
export const cartProductList = (userId) =>
  axios.post(`${url}/cart_list_by_user`, userId);

//add device
export const addDeviceForCart = (userId) =>
  axios.post(`${url}/add_device`, userId);

export const removeCartListFromCart = (userId) =>
  axios.post(`${url}/remove_all_cart_items_by_user_id`, userId);

//add To Cart For Device
export const addProductToDevice = (product) =>
  axios.post(`${url}/product_add_cart_device`, product);

//Cart List By Device
export const cartListByDeviceId = (deviceId) =>
  axios.post(`${url}/cart_list_by_device`, deviceId);

//Offer
export const getProductOffer = () => axios.get(`${url}/cart_offer_list`);

//Apply Offer
export const applyOffer = (offer) =>
  axios.post(`${url}/apply_cart_offer`, offer);

//Featured Product By Category
export const featuredProductByCategory = (catId) =>
  axios.post(`${url}/populate_featured_product_category_by_id`, catId);

export const fetchFeaturedProduct = (id) => axios.post(`${url}/populate_featured_product_by_fetured_product_id`, id)

//Home Page Banner
export const getHomePageBanner = () => axios.get(`${url}/get_carosel_banner`);

//getUserFullDetails
export const fetchUserFullDetails = (userId) =>
  axios.post(`${url}/user_by_id`, userId);

//checkGuestUser if Exist
export const checkUserifExist = (userId) =>
  axios.post(`${url}/user_login_otp`, userId);

//login guest User
export const loginGuestuser = (userId) =>
  axios.post(`${url}/guest_user_login_otp`, userId);

//add user address
export const addUserAddress = (address) =>
  axios.post(`${url}/add_user_address`, address);

export const removeuseraddress = (addressData) =>
  axios.post(`${url}/remove_user_address`, addressData);

//add guest user address
export const addGuestUserAddress = (address) =>
  axios.post(`${url}/add_guest_user_address`, address);

//Cart To Save for later User
export const cartToSaveUser = (product) =>
  axios.post(`${url}/cart_to_save_user`, product);

//Save To Cart for later User
export const saveToCartUser = (product) =>
  axios.post(`${url}/save_to_cart_user`, product);

//Remove from save for later
export const removeFromSave = (saveId) =>
  axios.post(`${url}/save_remove`, saveId);

//SAVE FOR LATER LIST
export const saveforlaterlist = (userId) =>
  axios.post(`${url}/save_list_by_user`, userId);

//Cart to save by device
export const carttosavebydevice = (product) =>
  axios.post(`${url}/move_save_by_device`, product);

//save to cart by device
export const savetocartbydevice = (product) =>
  axios.post(`${url}/move_cart_by_device`, product);

//save for later list by device
export const saveforlaterlistbydevice = (deviceId) =>
  axios.post(`${url}/save_list_by_device`, deviceId);

//Cart product device to user
export const cartproductdevicetouser = (userId) =>
  axios.post(`${url}/device_cart_update_by_user`, userId);

//user Edit
export const userEdit = (value) => axios.post(`${url}/user_edit`, value);

//add guest user password
export const addGuestUserPassword = (value) =>
  axios.put(`${url}/add_guest_user_password`, value);

//WISHLIST ------------------>
export const wishlist = (id) => axios.post(`${url}/populate_product_by_id`, id);

//Add to wishList
export const addtowishlist = (productId) =>
  axios.post(`${url}/add_product_to_wishlist`, productId);

//remove from WoshList
export const removefromwishlist = (productId) =>
  axios.post(`${url}/remove_product_from_wishlist`, productId);

//VIEW SIMILER PRODUCTS ---------------------->>
export const viewSimilerList = (catProductId) =>
  axios.post(`${url}/similar_product`, catProductId);

//Review Products --------------------->>>
export const addproductreview = (review) =>
  axios.post(`${url}/product_review`, review);

export const addImageForReview = (image) =>
  axios.post(`${url}/add_review_image`, image);

export const removeimageforreview = (id) =>
  axios.post(`${url}/remove_image`, id);

export const productReviewList = (productId) =>
  axios.post(`${url}/review_list_product`, productId);

export const productreviewlistByUser = (userId) =>
  axios.post(`${url}/review_list_user`, userId);

export const reviewByUserIdAndProductId = (ids) =>
  axios.post(`${url}/review_by_product_user`, ids);

export const updateproductReviewByUser = (reviewId) =>
  axios.put(`${url}/update_product_review_by_user`, reviewId);

//Make a Order ------------------------>>>
export const makeAOrder = (allDetails) =>
  axios.post(`${url}/order_create`, allDetails);

export const makeMultipleorder = (orders) =>
  axios.post(`${url}/create_multiple_order`, orders);

export const orderListByUserId = (userId) =>
  axios.post(`${url}/order_list_by_user`, userId);

//HOME PAGE DESIGN --------------------------------------->>
export const getHomePagedesign = (id, limit, pageIndex) =>
  axios.get(`${url}/get_page_design/${id}?limit=${limit}&page=${pageIndex}`);

//SET AND COMBO PRODUCT ----------------------------->>
export const getSetProductByProductId = (productId) =>
  axios.post(`${url}/find_combo_product_by_combo_product_id`, productId);

//TRAMS AND CONDITIONS ------------------------------->>>
export const getTramsandConditions = (countryCode) =>
  axios.post(
    `${url}/find_terms_and_conditions_and_privacy_policy_by_country_code`,
    countryCode
  );

//SHIPING --------------------------->>>>
export const fetchShippingDetails = (countryCode) =>
  axios.post(`${url}/get_shipping_by_country`, countryCode);

export const fetchShippingDetailsByPin = (pin) =>
  axios.post(`${url}/check_shipping_by_pin`, pin);

//PAGE INFO --------------------------->>>>
export const fetchAllPageInfo = () => axios.get(`${url}/get_page_list`);

//GOOGLE API ----------->>>
export const fetchGeoLocation = (lat, lng) => axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAM7yjSDq3X-pzq2A0mNEcbYcqMMRHLlIQ`)
