import {
  FETCH_HOMEPAGE_BANNER,
  FETCH_HOMEPAGE_DESIGN,
  FETCH_HOMEPAGE_DESIGN_SUCCESS,
  FETCH_HOMEPAGE_DESIGN_FALIUR,
  FETCH_CATEGORY_FIRST,
  FETCH_CATEGORY_FIRST_FALIUR,
  FETCH_CATEGORY_FIRST_SUCCESS,
  FETCH_CATEGORY_SECOND,
  FETCH_CATEGORY_SECOND_FALIUR,
  FETCH_CATEGORY_SECOND_SUCCESS,
  FETCH_CATEGORY_BANNER,
  FETCH_CATEGORY_BANNER_FALIUR,
  FETCH_CATEGORY_BANNER_SUCCESS
} from "../../constants/Home.type";
import { getHomePageBanner, getHomePagedesign } from "../../../api/api";

import store from '../../store'

export const homePageBanner = () => async (dispatch) => {
  try {
    const { data } = await getHomePageBanner();

    dispatch({ type: FETCH_HOMEPAGE_BANNER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const homePageDesign = (id, limit, page, pagetype) => (dispatch) => {
  try {
    if(id === '12345'){
      getHomePagedesign(id, limit, page).then(res => {
        if(JSON.stringify(res.data?.design) !== JSON.stringify(store.getState()?.home?.homePageDesign?.design)){
          dispatch({ type: FETCH_HOMEPAGE_DESIGN });
          dispatch({ type: FETCH_HOMEPAGE_DESIGN_SUCCESS, payload: res.data });
        }
      }).catch((err) => {
        dispatch({
          type: FETCH_HOMEPAGE_DESIGN_FALIUR,
          payload: "no more item",
        });
      });
    }else if(pagetype === "category2"){
      getHomePagedesign(id, limit, page).then(res => {
        if(JSON.stringify(res.data?.design) !== JSON.stringify(store.getState()?.home?.category1?.banner?.design)){
          dispatch({ type: FETCH_CATEGORY_FIRST })
          dispatch({ type: FETCH_CATEGORY_FIRST_SUCCESS, payload: res.data })
        }
      }).catch((err) => {
        dispatch({
          type: FETCH_CATEGORY_FIRST_FALIUR,
          payload: "no more item",
        });
      });
    }else if(pagetype === 'category3'){
      getHomePagedesign(id, limit, page).then(res => {
        if(JSON.stringify(res.data?.design) !== JSON.stringify(store.getState()?.home?.category2?.banner?.design)){
          dispatch({ type: FETCH_CATEGORY_SECOND })
          dispatch({ type: FETCH_CATEGORY_SECOND_SUCCESS, payload: res.data })
        }
      }).catch((err) => {
        dispatch({
          type: FETCH_CATEGORY_SECOND_FALIUR,
          payload: "no more item",
        });
      });
    }else if(pagetype === 'category1'){
      getHomePagedesign(id, limit, page).then(res => {
        if(JSON.stringify(res.data?.design) !== JSON.stringify(store.getState()?.home?.category?.banner?.design)){
          dispatch({ type: FETCH_CATEGORY_BANNER })
          dispatch({ type: FETCH_CATEGORY_BANNER_SUCCESS, payload: res.data })
        }
      }).catch((err) => {
        dispatch({
          type: FETCH_CATEGORY_BANNER_FALIUR,
          payload: "no more item",
        });
      });
    }
    // dispatch({ type: FETCH_HOMEPAGE_DESIGN });
    // getHomePagedesign(id, limit, page)
    //   .then((res) => {
    //     dispatch({ type: FETCH_HOMEPAGE_DESIGN_SUCCESS, payload: res.data });
    //   })
    //   .catch((err) => {
    //     dispatch({
    //       type: FETCH_HOMEPAGE_DESIGN_FALIUR,
    //       payload: "no more item",
    //     });
    //   });
  } catch (error) {
    console.log(error);
    dispatch({ type: FETCH_HOMEPAGE_DESIGN_FALIUR, payload: "no more item" });
  }
};
