import {
  ADD_REVIEW,
  ADD_IMAGE_FOR_REVIEW,
  PRODUCT_REVIEW_LIST,
  REMOVE_IMAGE_FOR_REVIEW,
  PRODUCT_REVIEW_LIST_BY_USER,
  REVIEW_BY_USER_AND_PRODUCT_ID,
  REMOVE_ALL_IMAGE_FOR_REVIEW,
} from "../../constants/Review.type";

const initState = {
  reviewImage: [],
};

const reviewReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_REVIEW:
      return {
        ...state,
        addReview: action.payload,
      };
    case ADD_IMAGE_FOR_REVIEW:
      return {
        ...state,
        reviewImage: [...state.reviewImage, action.payload],
      };
    case PRODUCT_REVIEW_LIST:
      return {
        ...state,
        reviewList: action.payload,
      };
    case REMOVE_IMAGE_FOR_REVIEW:
      return {
        ...state,
        reviewImage: state.reviewImage.filter(
          (image) => image.image_url.split("/")[8] !== action.payload
        ),
      };
    case PRODUCT_REVIEW_LIST_BY_USER:
      return {
        ...state,
        reviewListByUser: action.payload,
      };
    case REVIEW_BY_USER_AND_PRODUCT_ID:
      return {
        ...state,
        reaviewById: action.payload,
      };
    case REMOVE_ALL_IMAGE_FOR_REVIEW:
      return {
        ...state,
        reviewImage: [],
      };
    default:
      return state;
  }
};

export default reviewReducer;
