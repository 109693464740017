import {
  FETCH_ALL_CATEGORIES,
  FETCH_SINGLE_CATEGORIES,
  FETCH_CHILD_CATEGORIES,
  FETCH_CHILD_CATEGORIES_SUCCESS,
  FETCH_CHILD_CATEGORIES_FALIUR,
  FETCH_FEATURED_PRODUCT_BY_CAT,
  FETCH_FEATURED_PRODUCT_BY_CAT_SUCCESS,
  FETCH_FEATURED_PRODUCT_BY_CAT_FALIUR,
  FETCH_SINGLE_CATEGORIES_SUCCESS,
  FETCH_SINGLE_CATEGORIES_FALIUR,
  FETCH_FEATURED_PRODUCT,
  FETCH_FEATURED_PRODUCT_FALIUR,
  FETCH_FEATURED_PRODUCT_SUCCESS,
  FETCH_PARENT_CATEGORY,
  FETCH_PARENT_CATEGORY_FALIUR,
  FETCH_PARENT_CATEGORY_SUCCESS
} from "../../constants/Categories.type";

const initialState = {
  loading: true,
  category: null,
  childCategory: null,
  featuredLoading: true,
  featuredProductLoading:true,
  parentCategory:null,
  parentCategoryLoading:true
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_CATEGORIES:
      return {
        ...state,
        categoryList: action.payload,
      };
    case FETCH_SINGLE_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case FETCH_SINGLE_CATEGORIES_FALIUR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_CHILD_CATEGORIES:
      return {
        ...state,
        loading: false,
      };
    case FETCH_CHILD_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: true,
        childCategory: action.payload,
      };
    case FETCH_CHILD_CATEGORIES_FALIUR:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case FETCH_FEATURED_PRODUCT_BY_CAT:
      return {
        ...state,
        featuredLoading: true,
      };
    case FETCH_FEATURED_PRODUCT_BY_CAT_SUCCESS:
      return {
        ...state,
        featuredLoading: false,
        featuredCategoryByCategory: action.payload,
      };
    case FETCH_FEATURED_PRODUCT_BY_CAT_FALIUR:
      return {
        ...state,
        featuredLoading: false,
        error: action.payload,
      };
    case FETCH_FEATURED_PRODUCT:
      return{
        ...state,
        featuredProductLoading: true
      }
    case FETCH_FEATURED_PRODUCT_SUCCESS:
      return{
        ...state,
        featuredProductLoading: false,
        featuredProduct: action.payload
      }
    case FETCH_FEATURED_PRODUCT_FALIUR:
      return{
        ...state,
        featuredProductLoading: false,
        featuredProduct: action.payload
      }
      case FETCH_PARENT_CATEGORY:
        return{
          ...state,
          parentCategoryLoading: true
        }
      case FETCH_PARENT_CATEGORY_SUCCESS:
        return{
          ...state,
          parentCategoryLoading: false,
          parentCategory: action.payload
        }
      case FETCH_PARENT_CATEGORY_FALIUR:
        return{
          ...state,
          parentCategoryLoading: false,
          error: action.payload
        }
    default:
      return state;
  }

};

export default categoryReducer;
