import { Box, Button, Grid } from "@material-ui/core";
import React from "react";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";
import { useHistory } from "react-router-dom";

export const DesignTitleBar = ({ design }) => {
  const optionButton = design["option_button"];
  const showOptionButton = design["showOptionButton"];
  const containerTitleDesign = design["container_title_design"];
  const containerSubtitleDesign = design["container_subtitle_design"];
  const history = useHistory();

  return (
    <div style={{ padding: "14px 5px 5px 5px", margin: "5px" }}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        direction="row"
      >
        <Grid style={{}} item direction="column">
          <div
            style={{
              fontSize: containerTitleDesign["container_title_fontSize"],
              color: containerTitleDesign["container_title_color"],
            }}
          >
            {containerTitleDesign["container_title_text"]}
          </div>
          <Box height="5px" />
          <div
            style={{
              fontSize: containerSubtitleDesign["container_subtitle_fontSize"],
              color: containerSubtitleDesign["container_subtitle_color"],
            }}
          >
            {containerSubtitleDesign["container_subtitle_text"]}
          </div>
        </Grid>
        <Grid style={{}} item>
          {showOptionButton ? (
            <Button
              onClick={() => {
                HandleOnDesignClick(optionButton, history);
              }}
              style={{
                fontSize: optionButton["fontSize"],
                color: optionButton["titleColor"],
                backgroundColor: optionButton["backgroundColor"],
              }}
              variant="contained"
            >
              {optionButton["title"]}
            </Button>
          ) : (
            <Box />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
