import {
  FETCH_ALL_PRODUCT,
  FETCH_SINGLE_PRODUCT,
  FETCH_PRODUCT_WISE_CATEGORY,
  FETCH_PRODUCT_WISE_CATEGORY_SUCCESS,
  FETCH_PRODUCT_WISE_CATEGORY_FALIUR,
  GET_PRODUCT_OFFER,
  APPLY_OFFER,
  SIMILER_PRODUCTS,
  PRODUCT_BY_ID,
  FETCH_SET_PRODUCT,
  FETCH_SET_PRODUCT_SUCCESS,
  FETCH_SET_PRODUCT_FALIUR,
  SAVE_PRODUCT_FROM_PAGINATION,
  FETCH_CATEGORY_FIRST_PRODUCT,
  FETCH_CATEGORY_FIRST_PRODUCT_FALIUR,
  FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS,
  FETCH_CATEGORY_SECOND_PRODUCT,
  FETCH_CATEGORY_SECOND_PRODUCT_FALIUR,
  FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS,
  SAVE_FIRST_CAT_PRODUCT,
  SAVE_SECOND_CAT_PRODUCT,
  SUB_CAT_PRODUCT,
  SUB_CAT_PRODUCT_FALIUR,
  SUB_CAT_PRODUCT_SUCCESS,
  SAVE_SUB_CAT_PRODUCT
} from "../../constants/Product.type";

import {
  getProductList,
  getcategoryWiseProduct,
  getProductOffer,
  viewSimilerList,
  getSetProductByProductId,
  // productbyid,
} from "../../../api/api";

export const getallProductList = () => async (dispatch) => {
  try {
    const { data } = await getProductList();

    dispatch({ type: FETCH_ALL_PRODUCT, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getSingleProduct = (id) => async (dispatch) => {
  try {
    const { data } = await getProductList();

    const products = data.response;

    products &&
      products.map(
        (product) =>
          product._id === id &&
          dispatch({ type: FETCH_SINGLE_PRODUCT, payload: product })
      );
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryWiseProduct = (id,page, limit, pagetype) => (dispatch) => {
  try {
    if(id === '12345'){
      dispatch({ type: FETCH_CATEGORY_FIRST_PRODUCT });
      const variables = {
        cat_id: id,
      };
  
      getcategoryWiseProduct(variables, page, limit)
        .then((res) => {
          dispatch({
            type: FETCH_CATEGORY_FIRST_PRODUCT_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: FETCH_CATEGORY_FIRST_PRODUCT_FALIUR,
            payload: "no more product",
          });
        });
    }else if(pagetype === "category2"){
      dispatch({ type: FETCH_PRODUCT_WISE_CATEGORY });
      const variables = {
        cat_id: id,
      };
  
      getcategoryWiseProduct(variables, page, limit)
        .then((res) => {
          dispatch({
            type: FETCH_PRODUCT_WISE_CATEGORY_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: FETCH_PRODUCT_WISE_CATEGORY_FALIUR,
            payload: "no more product",
          });
        });
    }else if(pagetype === 'category3'){
      dispatch({ type: FETCH_CATEGORY_SECOND_PRODUCT });
      const variables = {
        cat_id: id,
      };
  
      getcategoryWiseProduct(variables, page, limit)
        .then((res) => {
          dispatch({
            type: FETCH_CATEGORY_SECOND_PRODUCT_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: FETCH_CATEGORY_SECOND_PRODUCT_FALIUR,
            payload: "no more product",
          });
        });
    }else if(pagetype === 'category1'){
      dispatch({ type: SUB_CAT_PRODUCT });
      const variables = {
        cat_id: id,
      };
  
      getcategoryWiseProduct(variables, page, limit)
        .then((res) => {
          dispatch({
            type: SUB_CAT_PRODUCT_SUCCESS,
            payload: res.data,
          });
        })
        .catch((err) => {
          dispatch({
            type: SUB_CAT_PRODUCT_FALIUR,
            payload: "no more product",
          });
        });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_PRODUCT_WISE_CATEGORY_FALIUR,
      payload: "no more product",
    });
  }
};

export const saveCategoryWiseProductForpagination = (allProduct, pagetype, id) => dispatch => {
  try {
    if(id === '12345'){
      dispatch({
        type: SAVE_FIRST_CAT_PRODUCT,
        payload: allProduct,
      });
    }else if(pagetype === "category2"){
      dispatch({
        type: SAVE_PRODUCT_FROM_PAGINATION,
        payload: allProduct,
      });
    }else if(pagetype === 'category3'){
      dispatch({
        type: SAVE_SECOND_CAT_PRODUCT,
        payload: allProduct,
      });
    }else if(pagetype === 'category1'){
      dispatch({
        type: SAVE_SUB_CAT_PRODUCT,
        payload: allProduct,
      });
    }
  } catch (error) {
    console.log(error)
  }
}

export const productOffer = () => async (dispatch) => {
  try {
    const { data } = await getProductOffer();
    dispatch({ type: GET_PRODUCT_OFFER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const applyForOffer = (res) => async (dispatch) => {
  try {
    const variables = {
      ...res,
      snackBar: true,
    };

    dispatch({ type: APPLY_OFFER, payload: variables });
  } catch (error) {
    console.log(error);
  }
};

export const viewsimilerList = (product) => async (dispatch) => {
  try {
    const variables = {
      cat_arr: product?.category_id,
      cur_prod_id: product?._id,
    };

    const { data } = await viewSimilerList(variables);

    dispatch({ type: SIMILER_PRODUCTS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const productById = (id) => async (dispatch) => {
  try {
    const { data } = await getProductList();
    const products = [];
    id.map((order) =>
      data.response.map(
        (product) => product._id === order && products.push(product)
      )
    );

    dispatch({ type: PRODUCT_BY_ID, payload: products });
  } catch (error) {
    console.log(error);
  }
};

//SET AND COMBO PRODUCT
export const getSetProductAndComboById = (comboId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SET_PRODUCT });
    const variables = {
      combo_product_id: comboId,
    };
    getSetProductByProductId(variables)
      .then((res) => {
        dispatch({ type: FETCH_SET_PRODUCT_SUCCESS, payload: res.data });
      })
      .catch(() => {
        dispatch({
          type: FETCH_SET_PRODUCT_FALIUR,
          payload: "no more combo product",
        });
      });
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_SET_PRODUCT_FALIUR,
      payload: "no more combo product",
    });
  }
};
