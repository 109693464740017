import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  CART_LIST_BY_USER,
  DELETE_PRODUCT,
  ADD_DEVICE_FOR_CART,
  ADD_PRODUCT_TO_DEVICE,
  SAVE_FOR_LETER_LIST,
  REMOVE_CART_LIST_FROM_CART,
} from "../../constants/Cart.type";
import {
  cartProductList,
  deleteProductfromCart,
  updateProductfromCart,
  addDeviceForCart,
  cartListByDeviceId,
  cartproductdevicetouser,
  cartToSaveUser,
  saveToCartUser,
  saveforlaterlist,
  saveforlaterlistbydevice,
  carttosavebydevice,
  savetocartbydevice,
  removeFromSave,
  removeCartListFromCart,
} from "../../../api/api";

export const addProduct = (res) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT, payload: res });
  } catch (error) {
    console.log(error);
  }
};

export const updateCartProduct = (cart) => async (dispatch) => {
  try {
    const variables = {
      cart_id: cart["_id"],
      product_per_unit_price: cart["product_per_unit_price"],
      product_quantity: cart["product_quantity"],
      product_per_unit_mrp: cart["product_per_unit_mrp"],
    };
    const { data } = await updateProductfromCart(variables);
    console.log(data);
    dispatch({ type: UPDATE_PRODUCT, payload: data });
    data && cart["user_id"]
      ? dispatch(cartListByUser(cart["user_id"]))
      : dispatch(cartListByDevice(localStorage.getItem("deviceId")));
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = (cart) => async (dispatch) => {
  try {
    const variables = {
      cart_id: cart["_id"],
    };
    const { data } = await deleteProductfromCart(variables);
    dispatch({ type: DELETE_PRODUCT, payload: data });
    data && cart["user_id"]
      ? dispatch(cartListByUser(cart["user_id"]))
      : dispatch(cartListByDevice(localStorage.getItem("deviceId")));
  } catch (error) {
    console.log(error);
  }
};

export const cartListByUser = (userId) => async (dispatch) => {
  try {
    const variables = {
      user_id: userId,
    };
    console.log(userId);
    if (userId) {
      const { data } = await cartProductList(variables);
      console.log(data);
      dispatch({ type: CART_LIST_BY_USER, payload: data });
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeAllCartList = (userId) => async (dispatch) => {
  try {
    const variables = {
      user_id: userId,
    };

    const { data } = await removeCartListFromCart(variables);

    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

export const addDevice = (id) => async (dispatch) => {
  try {
    const variables = {
      device_id: id,
      device_type: "mobile",
      token: id,
    };

    const { data } = await addDeviceForCart(variables);

    dispatch({ type: ADD_DEVICE_FOR_CART, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const addProductToDevice = (res) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PRODUCT_TO_DEVICE, payload: res });
  } catch (error) {
    console.log(error);
  }
};

export const cartListByDevice = (deviceId) => async (dispatch) => {
  try {
    const variables = {
      device_id: deviceId,
    };

    const { data } = await cartListByDeviceId(variables);

    dispatch({ type: CART_LIST_BY_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const cartProductDeviceToUser = (userId, deviceId) => async (
  dispatch
) => {
  try {
    const variables = {
      user_id: userId,
      device_id: deviceId,
    };

    const { data } = await cartproductdevicetouser(variables);

    dispatch({ type: CART_LIST_BY_USER, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const carttosaveuser = (product, userId) => async (dispatch) => {
  try {
    const variables = {
      cart_id: product._id,
      user_id: userId,
      custom_varient_id: product.custom_varient_id,
      product_id: product.product_id,
      product_name: product.product_name,
      product__main_image: product.product__main_image,
      product_size: product.product_size,
      product_color: product.product_color,
      product_addon: product.product_addon,
      product_brand: product.product_brand,
      product_set: product.product_set,
      product_estimate_delivery_date: product.product_estimate_delivery_date,
      product_quantity: product.product_quantity,
      product_per_unit_price: parseFloat(product.product_per_unit_price),
      product_mrp: parseFloat(product.product_mrp),
      product_discount: product.product_discount,
      product_price: parseFloat(product.product_price),
    };

    const { data } = await cartToSaveUser(variables);

    console.log(data);

    dispatch(saveForLaterList(userId));
    dispatch(cartListByUser(userId));
  } catch (error) {
    console.log(error);
  }
};

export const savetocartuser = (product, userId) => async (dispatch) => {
  try {
    const variables = {
      save_id: product._id,
      user_id: userId,
      custom_varient_id: product.custom_varient_id,
      product_id: product.product_id,
      product_name: product.product_name,
      product__main_image: product.product__main_image,
      product_size: product.product_size,
      product_color: product.product_color,
      product_addon: product.product_addon,
      product_brand: product.product_brand,
      product_set: product.product_set,
      product_estimate_delivery_date: product.product_estimate_delivery_date,
      product_quantity: product.product_quantity,
      product_per_unit_price: parseFloat(product.product_per_unit_price),
      product_mrp: parseFloat(product.product_mrp),
      product_discount: product.product_discount,
      product_price: parseFloat(product.product_price),
    };

    const { data } = await saveToCartUser(variables);

    console.log(data);

    dispatch(saveForLaterList(userId));
    dispatch(cartListByUser(userId));
  } catch (error) {
    console.log(error);
  }
};

export const saveForLaterList = (userId) => async (dispatch) => {
  try {
    const variables = {
      user_id: userId,
    };

    const { data } = await saveforlaterlist(variables);

    dispatch({ type: SAVE_FOR_LETER_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const cartToSaveByDevice = (product, deviceId) => async (dispatch) => {
  try {
    const variables = {
      cart_id: product._id,
      device_id: deviceId,
      custom_varient_id: product.custom_varient_id,
      product_id: product.product_id,
      product_name: product.product_name,
      product__main_image: product.product__main_image,
      product_size: product.product_size,
      product_color: product.product_color,
      product_addon: product.product_addon,
      product_brand: product.product_brand,
      product_set: product.product_set,
      product_estimate_delivery_date: product.product_estimate_delivery_date,
      product_quantity: product.product_quantity,
      product_per_unit_price: parseFloat(product.product_per_unit_price),
      product_mrp: parseFloat(product.product_mrp),
      product_discount: product.product_discount,
      product_price: parseFloat(product.product_price),
    };

    const { data } = await carttosavebydevice(variables);

    console.log(data);

    dispatch(saveForLaterListByDevice(deviceId));
    dispatch(cartListByDevice(deviceId));
  } catch (error) {
    console.log(error);
  }
};

export const saveToCartByDevice = (product, deviceId) => async (dispatch) => {
  try {
    const variables = {
      save_id: product._id,
      device_id: deviceId,
      custom_varient_id: product.custom_varient_id,
      product_id: product.product_id,
      product_name: product.product_name,
      product__main_image: product.product__main_image,
      product_size: product.product_size,
      product_color: product.product_color,
      product_addon: product.product_addon,
      product_brand: product.product_brand,
      product_set: product.product_set,
      product_estimate_delivery_date: product.product_estimate_delivery_date,
      product_quantity: product.product_quantity,
      product_per_unit_price: parseFloat(product.product_per_unit_price),
      product_mrp: parseFloat(product.product_mrp),
      product_discount: product.product_discount,
      product_price: parseFloat(product.product_price),
    };

    const { data } = await savetocartbydevice(variables);

    console.log(data);

    dispatch(saveForLaterListByDevice(deviceId));
    dispatch(cartListByDevice(deviceId));
  } catch (error) {
    console.log(error);
  }
};

export const saveForLaterListByDevice = (deviceId) => async (dispatch) => {
  try {
    const variables = {
      device_id: deviceId,
    };

    const { data } = await saveforlaterlistbydevice(variables);

    dispatch({ type: SAVE_FOR_LETER_LIST, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const removeProductFromSaveByUser = (saveId, userId) => async (
  dispatch
) => {
  try {
    const variables = {
      save_id: saveId,
    };

    const { data } = await removeFromSave(variables);

    console.log(data);

    dispatch(saveForLaterList(userId));
  } catch (error) {
    console.log(error);
  }
};

export const removeProductFromSaveByDevice = (saveId, deviceId) => async (
  dispatch
) => {
  try {
    const variables = {
      save_id: saveId,
    };

    const { data } = await removeFromSave(variables);

    console.log(data);

    dispatch(saveForLaterListByDevice(deviceId));
  } catch (error) {
    console.log(error);
  }
};
