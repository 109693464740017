export const HandleOnDesignClick = (item, history) => {
  console.log(item);

  const id = item["applied_to_id"] ?? "";
  const pageType = item["check_page_type"] ?? "";

  console.log(`HANDLE CLICK`);
  console.log(id, pageType);

  if (id.length === 0) {
    return;
  }

  if (pageType === "category1" || pageType === "category2" || pageType === "category3" || pageType === "category") {
    history.push({
      pathname: `/design-inner-page/${item?.applied_to}/${pageType}/${id}`,
      state: item?.applied_to
    });
    return;
  } else {
    history.push({
      pathname: `/feature-page/${id}`,
      state: item?.applied_to,
    });
  }
};
