export const FETCH_HOMEPAGE_BANNER = "FETCH_HOMEPAGE_BANNER";
export const FETCH_HOMEPAGE_DESIGN = "FETCH_HOMEPAGE_DESIGN";
export const FETCH_HOMEPAGE_DESIGN_SUCCESS = "FETCH_HOMEPAGE_DESIGN_SUCCESS";
export const FETCH_HOMEPAGE_DESIGN_FALIUR = "FETCH_HOMEPAGE_DESIGN_FALIUR";
export const FETCH_CATEGORY_FIRST = 'FETCH_CATEGORY_FIRST'
export const FETCH_CATEGORY_FIRST_SUCCESS = 'FETCH_CATEGORY_FIRST_SUCCESS'
export const FETCH_CATEGORY_FIRST_FALIUR = 'FETCH_CATEGORY_FIRST_FALIUR'
export const FETCH_CATEGORY_SECOND = 'FETCH_CATEGORY_SECOND'
export const FETCH_CATEGORY_SECOND_SUCCESS = 'FETCH_CATEGORY_SECOND_SUCCESS'
export const FETCH_CATEGORY_SECOND_FALIUR = 'FETCH_CATEGORY_SECOND_FALIUR'
export const FETCH_CATEGORY_BANNER = 'FETCH_CATEGORY_BANNER'
export const FETCH_CATEGORY_BANNER_SUCCESS = 'FETCH_CATEGORY_BANNER_SUCCESS'
export const FETCH_CATEGORY_BANNER_FALIUR = 'FETCH_CATEGORY_BANNER_FALIUR'
