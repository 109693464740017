import React from "react";


// components
import Header from "../../components/Header/Header";
import { NewHomePage }from"../NewHomePage/NewHomePage"

const Home = () => {

  return (
    <div style={{ width: "100%", overflowX: "hidden" }}>
      <Header />
      <NewHomePage />
    </div>
  );
};

export default Home;
