import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { HandleOnDesignClick } from "../../util/handleOnDesignClick";

import { useHistory } from "react-router-dom";

export const DesignTextView = ({ design }) => {
  const items = design["data"];
  const containerTitle =
    design["container_title_design"]["container_title_text"];

  const containerSubtitle =
    design["container_subtitle_design"]["container_subtitle_text"];
  const history = useHistory();

  const renderTextTile = (text, appliedToId, e) => {
    return (
      <div key={design['_id']}>
        <Paper
          onClick={() => {
            HandleOnDesignClick(e, history);
            // history.push(`/design-inner-page/${appliedToId}`);
          }}
          elevation={5}
          style={{ padding: "15px", margin: "5px" }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <div
                style={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {text}
              </div>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardIos style={{ color: "grey", fontSize: "15px" }} />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };

  return (
    <div style={{ margin: "20px 5px" }}>
      <div style={{ fontSize: "18px", marginLeft: "5px" }}>
        {containerTitle}
      </div>
      <Box height="7px" />
      <div style={{ fontSize: "14px", color: "grey", marginLeft: "5px" }}>
        {containerSubtitle}
      </div>
      <Box height="7px" />
      <Grid style={{}} container>
        {items.map((e) => {
          const appliedToId = e["applied_to_id"];
          const text = e["texts"][0]["text_value"];
          return (
            <Grid key={e['_id']} item xs={6}>
              {renderTextTile(text, appliedToId, e)}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
