import { Paper } from "@material-ui/core";
import React from "react";
import { ProductTile2 } from "../Product/ProductTile2/ProductTile2";

export const DesignProductListView = ({ design }) => {
  const allProducts = design["product_data"];
  const containerTitleDesign = design["container_title_design"];
  const containerSubtitleDesign = design["container_subtitle_design"];

  return (
    <Paper key={design['_id']} elevation={5} style={{ margin: "5px", padding: "5px" }}>
      {design["showTitleBar"] ? (
        <div style={{ marginLeft: "15px" }}>
          <div
            style={{
              fontSize: containerTitleDesign["container_title_fontSize"],
              color: containerTitleDesign["container_title_color"],
            }}
          >
            {containerTitleDesign["container_title_text"]}
          </div>
          <div
            style={{
              fontSize: containerSubtitleDesign["container_subtitle_fontSize"],
              color: containerSubtitleDesign["container_subtitle_color"],
              marginTop: "5px",
            }}
          >
            {containerSubtitleDesign["container_subtitle_text"]}
          </div>
        </div>
      ) : (
        <div />
      )}

      <div style={{ backgroundColor: "#eeeeee" }}>
        {allProducts.map((e) => {
          return (
            <ProductTile2
              {...{
                imageUrl: e["cover_image"],
                productTitle: e["product_name"],
                item: e,
              }}
            />
          );
        })}
      </div>
    </Paper>
  );
};
