import { combineReducers } from "redux";

import LogoCaptionReducer from "./reducers/LogoCaption/LogoCaption.reducer";
import productReducer from "./reducers/Product/Product.reducer";
import categoryReducer from "./reducers/Category/Category.reducer";
import userReducer from "./reducers/User/User.reducer";
import cartReducer from "./reducers/Cart/Cart.reducers";
import homeReducer from "./reducers/Home/Home.reducer";
import wishListReducer from "./reducers/WishList/WishList.reducer";
import reviewReducer from "./reducers/Review/Review.reducer";
import orderReducer from "./reducers/Order/Order.reducer";
import TramsAndConditionsReducer from "./reducers/TramsAndConditions/TramsAndConditions.reducer";
import shippingReducer from "./reducers/Shipping/Shipping.reducer";
import pageInfoReducer from "./reducers/PageInfo/PageInfo.reducer";
import locationReducer from './reducers/Location/Location.reducer'

export default combineReducers({
  logoCaption: LogoCaptionReducer,
  product: productReducer,
  category: categoryReducer,
  user: userReducer,
  cart: cartReducer,
  home: homeReducer,
  wishList: wishListReducer,
  review: reviewReducer,
  order: orderReducer,
  tramsAndConditions: TramsAndConditionsReducer,
  shipping: shippingReducer,
  pageInfo: pageInfoReducer,
  location: locationReducer
});
