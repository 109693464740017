import { SET_LATLNG } from '../../constants/Location.type'

const locationReducer = (state = [], action) => {
   switch(action.type){
       case SET_LATLNG:
           return{
               ...state,
               locationLatLng: action.payload
           }
       default:
           return state
   }
}

export default locationReducer