import {
  SET_CURRENT_USER,
  SET_COUNTRY_CODE,
  FORGOT_PASSWORD,
  USER_LOGIN,
  USER_SIGNUP,
  FETCH_USER_FULL_DETAILS,
  SEND_OTP,
  ADD_USER_ADDRESS,
  ADD_GUEST_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  COUNTRY_CODE_BY_IP,
  SELECT_ADDRESS,
} from "../../constants/User.type";
import {
  getCountryCodeByIp,
  fetchUserFullDetails,
  addUserAddress,
  addGuestUserAddress,
  userDetailsByIp,
  userEdit,
  addGuestUserPassword,
  removeuseraddress,
} from "../../../api/api";

import { v4 as uuid } from "uuid";

import jwtDecode from "jwt-decode";

// import timezonedata from '../../../data/tz-locales.json'
import countryData from "../../../data/countries.json";

export const setCurrentUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    // if (token === undefined) {
    //   localStorage.removeItem("token");
    // }
    let user = jwtDecode(token);
    dispatch({ type: SET_CURRENT_USER, payload: user });

    //  else {
    //   localStorage.removeItem("token");
    // }
  } catch (error) {
    console.log(error);
  }
};

export const userSignUp = (newUser) => async (dispatch) => {
  try {
    console.log(newUser?.response?.token);
    if (newUser?.response?.token) {
      localStorage.setItem("token", newUser?.response?.token);
    }
    dispatch({ type: USER_SIGNUP, payload: newUser });
    dispatch(setCurrentUser());
  } catch (error) {
    console.log(error);
  }
};

export const userLOGIN = (user) => async (dispatch) => {
  try {
    if (user?.response?.token) {
      localStorage.setItem("token", user?.response?.token);
    }

    dispatch({ type: USER_LOGIN, payload: user });
    dispatch(setCurrentUser());
  } catch (error) {
    console.log(error);
  }
};

export const forgotPassword = (user) => async (dispatch) => {
  try {
    console.log(user);
    dispatch({ type: FORGOT_PASSWORD, payload: user });
  } catch (error) {
    console.log(error);
  }
};

export const getCountryCode = () => async (dispatch) => {
  try {
    const { data } = await userDetailsByIp();
    console.log(data?.country);

    const variables = {
      country_name: data?.country_name,
    };

    const { data: userDetails } = await getCountryCodeByIp(variables);

    dispatch({ type: SET_COUNTRY_CODE, payload: userDetails });
  } catch (error) {
    console.log(error);
  }
};

export const getCountryByIp = (timezone) => async (dispatch) => {
  try {
    let data = await userDetailsByIp();
    if (data && data?.status !== 429) {
      const variables = {
        country_code: data?.data?.country,
        country_name: data?.data?.country_name,
        country_phone: data?.data?.country_calling_code,
        currency: data?.data?.currency,
      };
      dispatch({ type: COUNTRY_CODE_BY_IP, payload: variables });
    } else {
    }
    const country = countryData.filter(
      (item) => item.timezones[0] === timezone
    );
    console.log(country);
    const variables = {
      country_code: country[0].country_code,
      country_name: country[0].name,
    };
    dispatch({ type: COUNTRY_CODE_BY_IP, payload: variables });
  } catch (error) {
    console.log(error);
  }
};

export const getUserFullDetails = (userId) => async (dispatch) => {
  try {
    const variables = {
      user_id: userId,
    };
    const { data } = await fetchUserFullDetails(variables);

    dispatch({ type: FETCH_USER_FULL_DETAILS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const sendOTP = (res) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP, payload: res });
  } catch (error) {
    console.log(error);
  }
};

export const userAddAddress = (address) => async (dispatch) => {
  try {
    const variables = {
      _id: address.id,
      address: [
        {
          name: address.name,
          countrycode: address.countryCode,
          phone: address.phone,
          house_number: address.house_number,
          street: address.street,
          zip_code: address.zip_code,
          city: address.city,
          state: address.state,
          country: address?.country,
          landmark: address.landmark,
          address_type: address.type,
          uid: uuid(),
          country_iso: address.country_iso,
          state_iso: address.state_iso,
        },
      ],
    };

    const { data } = await addUserAddress(variables);

    console.log(data);
    if (data?.status === "fail") {
      addGuestUserAddress(address);
    } else if (data?.status === "success") {
      dispatch(
        selectAddress(
          data?.response?.address[data?.response?.address?.length - 1]
        )
      );
    }
    dispatch({ type: ADD_USER_ADDRESS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const removeUserAddress = (userId, addressId) => async (dispatch) => {
  try {
    const variables = {
      user_id: userId,
      address_id: addressId,
    };

    const { data } = await removeuseraddress(variables);

    console.log(data);

    dispatch(getUserFullDetails(userId));
  } catch (error) {
    console.log(error);
  }
};

export const addGuestUsersAddress = (address) => async (dispatch) => {
  try {
    const variables = {
      _id: address.id,
      address: [
        {
          name: address.name,
          countrycode: address.countryCode,
          phone: address.phone,
          house_number: address.house_number,
          street: address.street,
          zip_code: address.zip_code,
          city: address.city,
          state: address.state,
          country: address.country,
          landmark: address.landmark,
        },
      ],
    };

    const { data } = await addGuestUserAddress(variables);

    dispatch({ type: ADD_GUEST_USER_ADDRESS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const updateuserDetails = (userDetails, id) => async (dispatch) => {
  try {
    const variables = {
      edit_id: id,
      changes: {
        fullname: userDetails.userName,
        email: userDetails.email,
        phone: userDetails.phone,
      },
    };

    const { data } = await userEdit(variables);

    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

export const updateUserAddress = (userId, res) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_USER_ADDRESS, payload: res });
    dispatch(getUserFullDetails(userId));
  } catch (error) {
    console.log(error);
  }
};

export const updateUserGender = (userGender, id) => async (dispatch) => {
  try {
    const variables = {
      edit_id: id,
      changes: {
        gender: userGender,
      },
    };

    const { data } = await userEdit(variables);

    console.log(data);
    dispatch(getUserFullDetails(id));
  } catch (error) {
    console.log(error);
  }
};

export const GuestUserPasswordUpdate =
  (userPassword, id) => async (dispatch) => {
    try {
      const variables = {
        _id: id,
        password: userPassword,
      };

      const { data } = await addGuestUserPassword(variables);

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

export const selectAddress = (address) => async (dispatch) => {
  try {
    localStorage.setItem("addressId", address?.uid);
    dispatch({ type: SELECT_ADDRESS, payload: address });
  } catch (error) {
    console.log(error);
  }
};
