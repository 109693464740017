import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  useScrollTrigger,
  Slide,
  Badge,
  Modal,
} from "@material-ui/core";
import { KeyboardBackspace, Favorite } from "@material-ui/icons";
// assets
// import arr_left from "../../assets/icons/icon-left-arrow.png";
import icon_search from "../../assets/icons/Icon-search.png";
import icon_cart from "../../assets/icons/Icon-shopping-cart.png";

import { useSelector, useDispatch } from "react-redux";
import {
  cartListByUser,
  cartListByDevice,
} from "../../Redux/actions/Cart/Cart.action";
import { SearchPage } from "../../pages/SearchPage/SearchPage";
import { WishListLoginModal } from "../../pages/MyWishlist/WishListLoginModal";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
const AllProductListBackHedareBar = (props) => {
  const dispatch = useDispatch();
  const { headerTitle } = props;
  const history = useHistory();

  const cartList = useSelector((store) => store?.cart?.cartList?.response);
  const wishList = useSelector((state) => state?.wishList?.wishList?.response);
  const user = useSelector((state) => state?.user?.currentUser);
  const deviceId = localStorage.getItem("deviceId");
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const [OpenLoginToComplete, setOpenLoginToComplete] = React.useState(false);
  const [Snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setsnackbarMessage] = React.useState("");

  useEffect(() => {
    user
      ? dispatch(cartListByUser(user._id))
      : dispatch(cartListByDevice(deviceId));
  }, [user, deviceId, dispatch]);

  console.log("CART_LIST: ", cartList);
  return (
    <div style={{ height: 56 }}>
      <HideOnScroll {...props}>
        <AppBar className="bg-pink appbar_fixed">
          <Toolbar>
            <div className="display_flex__align" style={{ width: "70%" }}>
              <KeyboardBackspace
                style={{ marginRight: "5%", fontSize: 30 }}
                onClick={() => {
                  history.goBack();
                }}
              />
              <Typography
                style={{
                  color: "#ffffff",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                  width: "50%",
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  // marginBottom: "3%",
                }}
                className="heading"
                variant="h6"
              >
                {headerTitle}
              </Typography>
            </div>
            <div
              className="display_flex__align"
              style={{ width: "30%", justifyContent: "flex-end" }}
            >
              <Modal
                open={openSearchModal}
                onClose={() => setOpenSearchModal(false)}
              >
                <SearchPage {...{ setOpenSearchModal }} />
              </Modal>
              <img
                onClick={() => {
                  console.log("search called");
                  setOpenSearchModal(true);
                }}
                src={icon_search}
                width={25}
                style={{ marginRight: 15 }}
                alt="search"
              />
              <div style={{ position: "relative", cursor: "pointer" }}>
                {wishList &&
                wishList?.wishlist &&
                wishList?.wishlist.length !== 0 ? (
                  <Badge
                    badgeContent={wishList?.wishlist?.length}
                    color="secondary"
                    onClick={() => {
                      user?._id
                        ? history.push("/my-wishlist")
                        : setOpenLoginToComplete(true);
                    }}
                    style={{ marginRight: 15 }}
                  >
                    <Favorite style={{ fontSize: 25 }} />
                  </Badge>
                ) : (
                  <Favorite
                    style={{ marginRight: 15, fontSize: 25 }}
                    onClick={() => {
                      user?._id
                        ? history.push("/my-wishlist")
                        : setOpenLoginToComplete(true);
                    }}
                  />
                )}
              </div>
              <Modal
                open={OpenLoginToComplete}
                onClose={() => setOpenLoginToComplete(false)}
              >
                <WishListLoginModal
                  setOpenLoginToComplete={setOpenLoginToComplete}
                  setsnackbarMessage={setsnackbarMessage}
                  setSnackbar={setSnackbar}
                />
              </Modal>
              {cartList?.length !== 0 ? (
                <Badge
                  badgeContent={cartList?.length}
                  color="secondary"
                  onClick={() => history.push("/cart")}
                >
                  <img src={icon_cart} alt="cart icon" width={25} />
                </Badge>
              ) : (
                <div
                  onClick={() => history.push("/cart")}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <img src={icon_cart} alt="cart icon" width={25} />
                </div>
              )}
              {/* <div
                onClick={() => history.push("/cart")}
                style={{ position: "relative", cursor: "pointer" }}
              >
                <img src={icon_cart} alt="cart icon" width={25} />
                {cartList?.length !== 0 && (
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      position: "absolute",
                      top: "-7px",
                      right: "-5px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      backgroundColor: "red",
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {cartList?.length}
                  </div>
                )}
              </div> */}
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </div>
  );
};

export default AllProductListBackHedareBar;
